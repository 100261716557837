.blockchain {
  position: relative;
  background: url('../../assets/img/blockchain.jpg') center no-repeat;
  background-size: cover;
  padding: toRem(160px) 0 0;
  z-index: 1;

  @media (max-width: 960px) {
    padding: 105px 0 65px;
  }

  // &:before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   top: toRem(-150px);
  //   left: -10%;
  //   width: 120%;
  //   height: toRem(200px);
  //   background: #fff;
  //   rotate: -4deg;
  //   z-index: 2;
  // }

  &__line {
    width: 1px;
    height: 100%;
    z-index: -1;
    background: linear-gradient(0deg, rgba(224, 225, 226, 0.25) 0%, #E0E1E2 49.52%, rgba(224, 225, 226, 0.25) 99.04%);
    display: block;
    position: absolute;
    opacity: .1;

    &--first {
      left: 33.33333333%;
    }

    &--second {
      left: 66.66666666%;
    }
  }

  &__plus {
    position: absolute;
    z-index: 5;

    @media (max-width: 960px) {
      display: none;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      
      &:first-child {
        translate: 0 20%;
      }
    }

    &--top {
      top: toRem(40px);
      left: toRem(-50px);
      width: toRem(165px);
      height: toRem(165px);
      rotate: 20deg;
    }

    &--bottom {
      width: toRem(70px);
      height: toRem(70px);
      bottom: toRem(-15px);
      left: toRem(10px);
      rotate: 20deg;
    }
  }

  &__title {
    color: #F0DFFF;
    text-shadow: toRem(0px) toRem(4.42378044128418px) toRem(4.42378044128418px) rgba(0, 0, 0, 0.25);
    font-size: toRem(75.005px);
    font-style: normal;
    font-weight: 900;
    line-height: 96.3%;
    text-transform: uppercase;
    width: max-content;
    position: relative;
    z-index: 2;
    font-family: 'Benzin', 'Proxima Nova', sans-serif;

    @media (max-width: 960px) {
      font-size: 55px;
      justify-content: center;
      text-align: center;
      margin: auto;
    }

    @media (max-width: 640px) {
      font-size: 40px;
    }

    @media (max-width: 390px) {
      font-size: 32px;
    }

    svg {
      width: 100%;
      height: auto;
      z-index: -1;
      bottom: -10%;
      left: 0;
      position: absolute;
    }
  }

  &__desc {
    margin: toRem(47px) 0 toRem(45px);
    color: #FFF;
    font-size: toRem(22.502px);
    font-style: normal;
    font-weight: 300;
    line-height: 146.687%; /* 33.007px */

    @media (max-width: 960px) {
      text-align: center;
      margin: 35px auto 0px;

      br {
        display: none;
      }
    }

    b {
      font-weight: 700;
    }
  }

  &__video-link {
    padding: 0 toRem(130px) toRem(43px);

    &--text {
      font-family: $font-tr;
      color: #FFF;
      font-size: toRem(37.503px);
      line-height: 100%;
      letter-spacing: toRem(3px);
      text-transform: lowercase;

      span {
        color: #AB64F2;
      }
    }
  }

  &__we-use {
    position: relative;
    color: #F0DFFF;
    text-shadow: toRem(0px) toRem(4.42378044128418px) toRem(4.42378044128418px) rgba(0, 0, 0, 0.25);
    font-size: toRem(30px);
    font-weight: 900;
    line-height: 100%;
    text-transform: uppercase;
    z-index: 2;
    width: max-content;
    margin: toRem(80px) 0 toRem(40px);

    @media (max-width: 640px) {
      margin: 65px auto 40px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: toRem(-200px);
      height: toRem(47px);
      width: calc(100% + toRem(200px));
      translate: 0% -60%;
      z-index: -1;
      border-radius: 0 toRem(20px) toRem(20px) 0;
      opacity: 0.5;
      background: linear-gradient(90deg, $gradientStart -13.33%, $gradientEnd 88.89%);
    }
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: toRem(77px);

    .slick-slide {
      padding: 0 20px;

      @media (max-width: 460px) {
        padding: 0 25px;
      }
    }

    .slick-arrow {
      display: flex;
      align-items: center;
      width: 38px;
      height: 38px;
      position: absolute;
      top: 50%;
      left: 5px;
      translate: 0 -50%;
      z-index: 2;
      border: none;
      border-radius: 100px;
      background: #FAAE54;
    }
    
    .slick-next {
      right: 5px;
      left: auto;
    }

    @media (max-width: 1024px) {
      grid-template-columns: repeat(3,1fr);
    }

    @media (max-width: 960px) {
      margin: 0 -15px;
    }
  }

  &__cell {
    width: 100%;
    margin-bottom: toRem(-42px);

    &:nth-child(2n) {
      .blockchain__box {
        background: linear-gradient(180deg, #A71EFE -13.33%, #6916D0 88.89%);
      }
    }

    @media (max-width: 960px) {
      margin-bottom: 0;
    }
  }

  &__box {
    background: linear-gradient(0deg, #A71EFE -13.33%, #6916D0 88.89%);
    box-shadow: toRem(0px) toRem(4.37436px) toRem(65.61539px) toRem(10.9359px) rgba(73, 0, 143, 0.29);
    padding: toRem(9px);
    border-radius: toRem(21.872px);

    &--inset {
      border-radius: toRem(18px);
      border: toRem(1px) solid rgba(255, 255, 255, 0.1);
      padding: toRem(33px) toRem(5px) toRem(27px) toRem(18px);

      @media (max-width: 960px) {
        padding: toRem(51px) toRem(5px) toRem(40px) toRem(28px);
      }
    }

    &--icon {
      margin-bottom: toRem(20px);
      svg {
        height: toRem(60px);
        width: auto;

        @media (max-width: 960px) {
          height: 90px;
          margin-bottom: 10px;
        }

        @media (max-width: 374px) {
          height: 70px;
        }
      }
    }

    &--title {
      color: rgba(255, 255, 255, 0.9);
      font-size: toRem(24.608px);
      font-weight: 900;
      line-height: 146.687%;
      margin-bottom: toRem(13px);
      opacity: .9;

      @media (max-width: 960px) {
        font-size: 36px;
        line-height: 130%;
        margin-bottom: 21px;
      }

      @media (max-width: 460px) {
        font-size: 32px;
      }

      @media (max-width: 374px) {
        font-size: 24px;
      }
    }

    &--text {
      color: #FFF;
      font-size: toRem(15.31px);
      font-weight: 400;
      line-height: 146.687%;
      opacity: .9;

      @media (max-width: 960px) {
        font-size: 23px;
        line-height: 130%;
      }

      @media (max-width: 390px) {
        font-size: 20px;
      }

      @media (max-width: 374px) {
        font-size: 18px;
      }
    }

    @media (max-width: 960px) {
      max-width: 350px;
      width: 100%;
      margin: auto;
    }
  }
}