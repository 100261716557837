.ideology {
  padding: toRem(50px) 0 toRem(50px);
  overflow: hidden;
  position: sticky;
  top: 0;
  background: url('../../assets/img/ideology.png') center bottom no-repeat;
  background-size: 100%;
  height: 100vh;
  min-height: toRem(600px);

  @media (max-width: 1024px) {
    background-size: 300%;
  }

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    font-family: 'Benzin';
    position: relative;
    z-index: 2;

    b {
      font-size: toRem(75px);
      font-weight: 900;
      line-height: 100%; /* 67.725px */
      text-transform: uppercase;
      background: linear-gradient(88deg, #FFF 38.57%, rgba(255, 255, 255, 0.50) 98.14%);
      z-index: 2;
      position: relative;
  
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (max-width: 1024px) {
        font-size: 64px;
      }

      @media (max-width: 900px) {
        font-size: 50px;
      }

      @media (max-width: 768px) {
        font-size: 42px;
      }

      @media (max-width: 600px) {
        text-align: center;
        display: block;
        width: min-content;
        margin: 14px auto 0;
      }

      @media (max-width: 380px) {
        font-size: 32px;
      }
    }

    position: relative;

    span {
      color: #fff;
      background: transparent;
      background-clip: none;
      -webkit-background-clip: none;
      -webkit-text-fill-color: initial;

      font-size: toRem(53.169px);
      font-weight: 900;
      line-height: 100%; /* 48.012px */
      letter-spacing: toRem(2.393px);
      text-transform: uppercase;
      display: block;
      filter: url(#stroke-text-svg-filter);

      @media (max-width: 1024px) {
        font-size: 48px;
      }

      @media (max-width: 900px) {
        font-size: 34px;
      }

      @media (max-width: 768px) {
        font-size: 28px;
      }

      @media (max-width: 600px) {
        text-align: center;
      }

      @media (max-width: 460px) {
        font-size: 24px;
      }

      @media (max-width: 380px) {
        font-size: 18px;
      }
    }

    &:after {
      content: '';
      width: 70vw;
      height: toRem(50px);
      border-radius: toRem(21.363px);
      opacity: 0.5;
      position: absolute;
      background: linear-gradient(-90deg,rgba(3,0,91,.6) -13.33%,rgba(125, 0, 227, 0.9) 88.89%);
      left: 32%;
      z-index: -1;
      bottom: toRem(-15px);

      @media (max-width: 600px) {
        width: 90%;
        left: 5%;
        height: 34px;
        bottom: -15px;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    gap: toRem(15px);

    @media (max-width: 1024px) {
      justify-content: center;
      width: 100%;
    }
  }

  &__button {
    width: toRem(300px);
    height: toRem(58px);
    border-radius: toRem(5px);
    color: #FAAE54;
    font-family: 'Benzin';
    padding-right: toRem(20px);
    font-weight: 900;
    text-transform: uppercase;
    font-size: toRem(15px);

    text-shadow: toRem(0px) toRem(4px) toRem(4px) rgba(0, 0, 0, 0.25);

    i {
      &.icon {
        right: toRem(20px);
        
        svg, img {
          height: auto;
          width: toRem(14px);

          path {
            fill: #FAAE54;
          }
        }
      }
    }
  }

  &__list {
    &--track {
      display: flex;
      gap: toRem(200px);
      padding: toRem(100px) 0 toRem(36px);
      padding-left: calc((100% - toRem(1110px)) / 2);
      margin: 0;
      list-style: none;
      width: max-content;

      @media (max-width: 1024px) {
        padding-left: 60px;
      }

      @media (max-width: 640px) {
        gap: 120px;
      }

      @media (max-width: 420px) {
        width: calc((420px * 6) + (120px * 5));
      }
  
      li {
        position: relative;
        width: toRem(577px);
        min-width: toRem(577px);
        height: toRem(256px);
        z-index: 2;
        pointer-events: none;

        @media (max-width: 640px) {
          width: 80vw;
          min-width: 380px;
          height: auto;
        }
      }
    }
  }

  &__block {
    padding: toRem(92px) toRem(0px) toRem(43px) toRem(53px);

    @media (max-width: 640px) {
      padding: 58px 0 28px 34px;
    }

    &--background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &--number {
      position: absolute;
      top: 0;
      left: 0%;
      translate: -65% -10%;
  
      text-align: center;
      font-size: toRem(225.689px);
      font-weight: 900;
      line-height: 100%;
      text-transform: uppercase;
  
      font-family: 'Benzin';
      background: linear-gradient(1turn,rgba(3,0,91,.6) -13.33%,rgba(125, 0, 227, 0.9) 88.89%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      z-index: -2;

      @media (max-width: 640px) {
        font-size: 111px;
        line-height: 100%;
      }
    }

    &--title {
      white-space: nowrap;
      position: relative;
      width: max-content;
      z-index: 2;

      span {
        font-family: 'Benzin';
        font-size: toRem(49.322px);
        font-weight: 900;
        line-height: 100%; /* 47.448px */
        text-transform: uppercase;

        background: linear-gradient(0deg, #77509D -13.33%, #B66DFD 88.89%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: 640px) {
          font-size: 31px;
        }
      }

      &:before {
        font-family: 'Benzin';
        font-size: toRem(49.322px);
        font-weight: 900;
        line-height: 100%; /* 47.448px */
        text-transform: uppercase;

        content: attr(data-shadow);
        position: absolute;
        top: -40%;
        left: 0;
        width: 100%;
        height: 100%;
        scale: 1.13;
        z-index: -2;
        opacity: .05;
    
        background: transparent;
        background-clip: none;
        -webkit-background-clip: none;
        -webkit-text-fill-color: initial;

        @media (max-width: 640px) {
          font-size: 31px;
        }
      }
    }

    &--text {
      color: #4B3568;
      font-size: toRem(23.938px);
      font-weight: 300;
      line-height: 146.687%;
      opacity: 0.7;
      margin-top: toRem(8px);

      @media (max-width: 640px) {
        font-size: 15.2px;
      }

      b {
        font-weight: 900;
      }
    }

    &--picture {
      position: absolute;
      width: toRem(250px);
      top: 0;
      right: 0;

      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &--b1 {
      .ideology__block--number {
        translate: -100% -10%;
      }

      .ideology__block--picture {
        top: toRem(40px);
        right: toRem(-80px);
        width: toRem(260px);

        @media (max-width: 640px) {
          width: 180px;
          right: 0;
          right: -20px;
        }
      }
    }

    &--b2 {
      .ideology__block--picture {
        top: toRem(-40px);
        right: toRem(-140px);
        width: toRem(340px);
        rotate: -10deg;

        @media (max-width: 640px) {
          width: 240px;
          top: -20px;
          right: -90px;
        }
      }
    }

    &--b3 {
      .ideology__block--picture {
        width: toRem(290px);
        top: auto;
        bottom: toRem(-100px);
        right: toRem(-80px);

        @media (max-width: 640px) {
          width: 250px;
          top: 10px;
          right: -100px;
        }
      }
    }

    &--b4 {
      .ideology__block--picture {
        width: toRem(240px);
        top: toRem(20px);
        right: toRem(-100px);

        @media (max-width: 640px) {
          width: 200px;
          top: 0px;
          right: -80px;
        }
      }
    }

    &--b5 {
      .ideology__block--title {
        margin-top: toRem(-10px);
        &:before,
        span {
          font-size: toRem(30.814px);

          @media (max-width: 640px) {
            font-size: 22px;
          }
        }
      }
      .ideology__block--picture {
        width: toRem(280px);
        top: toRem(0px);
        right: toRem(-80px);

        @media (max-width: 640px) {
          width: 200px;
          top: 0px;
          right: -80px;
        }
      }
    }
  }
}

.ideology-scroll {
  position: relative;
}