.consumer-fund {
  background: #F7F1FF;
  position: relative;

  @media (max-width: 1024px) {
    padding: 110px 0 190px;
  }

  &__holder {
    position: relative;

    top: toRem(-75px);
    margin: 0 auto toRem(-120px);
    z-index: 3;
    max-width: toRem(1030px);
    width: 100%;

    @media (max-width: 1024px) {
      position: static;
      margin: 0;
    }
  }
  
  &__box {
    background: #F7F1FF;
    border-radius: toRem(26px);
    box-shadow: toRem(0px) toRem(11.07472px) toRem(44.29887px) toRem(0px) rgba(42, 0, 95, 0.13);
    padding: 0 toRem(50px) toRem(65px) toRem(215px);
    width: 100%;

    @media (max-width: 1024px) {
      padding: 0 24px 74px;
    }
  }

  &__top-title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    height: toRem(75px);
    border-radius: toRem(75px);
    background: linear-gradient(0deg, #77509D -13.33%, #B66DFD 88.89%);
    color: rgba(255, 255, 255, 0.6);
    font-family: 'Benzin';
    font-weight: 900;
    line-height: 100%;
    font-size: toRem(25px);
    top: calc(toRem(-75px) / 2);
    margin-bottom: calc(toRem(-75px) / 2);
    white-space: nowrap;
    width: max-content;
    padding: 0 toRem(50px) toRem(1px);
    left: toRem(-70px);

    @media (max-width: 1024px) {
      font-size: 26px !important;
      width: 100%;
      padding: 0 20px 0 !important;
      max-width: 620px;
      margin: auto;
      margin-bottom: calc(toRem(-75px) / 1);
      left: 0;
    }

    @media (max-width: 640px) {
      height: 50px;
      top: calc(toRem(-50px) / 2);
      margin-bottom: -50px;
      font-size: 22px !important;
    }

    @media (max-width: 460px) {
      font-size: 18px !important;
      white-space: initial;
      text-align: center;
    }

    @media (max-width: 420px) {
      font-size: 18px !important;
      height: 50px;
      top: calc(toRem(-40px) / 2);
      margin: 0 -20px -40px;
      width: calc(100% + 40px);
      letter-spacing: -.4px;
    }
  }

  &__title {
    position: relative;
    width: max-content;
    z-index: 2;
    margin-top: toRem(30px);

    span {
      font-family: 'Benzin';
      font-size: toRem(108px);
      font-weight: 900;
      text-transform: lowercase;
      font-variant: all-small-caps;

      background: linear-gradient(0deg, #77509D -13.33%, #B66DFD 88.89%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      line-height: 120%;

      @media (max-width: 768px) {
        font-size: 90px;
      }

      @media (max-width: 640px) {
        font-size: 70px;
      }

      @media (max-width: 520px) {
        font-size: 58px;
      }

      @media (max-width: 420px) {
        font-size: 40px;
      }
    }

    svg {
      width: 100%;
      height: auto;
      position: absolute;
      bottom: toRem(20px);
      left: 0;
      z-index: -1;

      @media (max-width: 768px) {
        bottom: 15px;
      }

      @media (max-width: 520px) {
        bottom: 10px;
      }
    }

    @media (max-width: 1024px) {
      margin: 35px auto 0;
    }
  }

  &__planet {
    display: block;
    width: toRem(390px);
    position: absolute;
    left: 0;
    top: 50%;
    translate: -50% -50%;
    z-index: 4;
    overflow: hidden;

    @media (max-width: 1024px) {
      left: 50%;
      top: auto;
      bottom: 0;
      translate: -50% 30%;
    }

    @media (max-width: 390px) {
      width: 100%;
    }

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      aspect-ratio: 1/1;
      object-fit: contain;
      top: 0;
      width: 100%;
      left: 0;
      height: 100%;
      z-index: 2;
    }

    &--background {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      svg {
        position: absolute;
        display: none;
        top: 50%;
        translate: -10% -50%;
        width: toRem(140px);
        z-index: -1;
        opacity: .6;
  
        &:first-child {
          left: 0;
          rotate: 90deg;
        }

        &:last-child {
          left: auto;
          right: 0;
          translate: 10% -50%;
          rotate: -90deg;
        }

        @media (max-width: 640px) {
          display: block;
        }
      }
    }
  }

  &__text {
    color: #4B3568;
    font-size: toRem(25.733px);
    font-weight: 300;
    line-height: 146.687%;
    opacity: 0.85;
    padding-right: toRem(30px);

    b {
      font-weight: 900;
    }

    @media (max-width: 1024px) {
      text-align: center;
    }

    @media (max-width: 640px) {
      font-size: 20px;
      margin-top: 20px;
    }

    @media (max-width: 640px) {
      font-size: 18px;
      margin-top: 20px;
    }
  }

  &__logo {
    position: absolute;
    top: 60%;
    translate: 0 -50%;
    right: toRem(-130px);
    rotate: -102deg;
    z-index: -1;

    svg {
      width: toRem(240px);
      height: auto;
      opacity: .6;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }
}