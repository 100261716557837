// Functions
@import 'Functions';
$default-font-size: calcFluidFontSize(14, 37.2777, 1440, 3840);

// Fonts
@import '../assets/fonts/DaoTrinity/font';
@import '../assets/fonts/ProximaNova/font';
@import '../assets/fonts/Poppins/font';
@import '../assets/fonts/Roboto/font';
@import '../assets/fonts/Benzin/font';

$gradientStart: rgba(3,0,91,.6);
$gradientEnd: rgba(125, 0, 227, 0.9);

$font-stack: 'Proxima Nova', sans-serif;
$font-poppins: 'Poppins', sans-serif;
$font-tr: 'Dao Trinity', sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-barlow: 'Barlow', sans-serif;

// Styles
@import 'Main';
@import 'Blocks/HeaderBlock';
@import 'Blocks/PromoBlock';
@import 'Blocks/RatRaceBlock';
@import 'Blocks/IdeologyBlock';
@import 'Blocks/StepsBlock';
@import 'Blocks/ConsumerFundBlock';
@import 'Blocks/BlockchainBlock';
@import 'Blocks/SmartContractBlock';
@import 'Blocks/ProgramsBlock';
@import 'Blocks/ConsumerPatternBlock';
@import 'Blocks/MoneyParadoxBlock';
@import 'Blocks/CareerBlock';
@import 'Blocks/PartnerProgramBlock';
@import 'Blocks/Footer';
@import 'Glitch';
@import 'Mac';

* {
  box-sizing: border-box !important;
  outline: none !important;
  appearance: none;
  -webkit-appearance: none;
  // cursor: none !important;
}

input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

a {
  text-decoration: none !important;
}

body, html {
  font: $default-font-size $font-stack;
  scrollbar-gutter: stable;
  //overflow-x: hidden;
  //overflow-y: auto;

  &.menu-fixed {
    overflow: hidden;
  }

  @media (max-width: 1024px) {
    font-size: 14px !important;
  }
}

.container {
  width: 100%;
  max-width: toRem(1140px);
  padding: 0 toRem(15px);
  margin: 0 auto;
}

// .cursor {
//   opacity: 0;
//   width: toRem(25px);
//   height: toRem(32px);
//   position: fixed;
//   border-radius: 50%;
//   transition: .025s ease-in-out transform, .1s ease-in-out opacity;
//   top: 0;
//   left: 0;
//   z-index: 20000;
//   pointer-events: none;

//   svg, img {
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 100%;
//       object-fit: contain;
//       transition: cubic-bezier(0.77, 0, 0.18, 1) 0.2s;
//       transform-origin: 20% 0%;
//   }

//   &.--hover {
//     svg, img {
//       rotate: 30deg;
//     }
//   }

//   @media (max-width: 1300px) {
//       display: none !important;
//   }
// }

// body {
//   &:hover {
//       .cursor {
//           opacity: 1;
//       }
//   }
// }