.rat-race-holder {
  position: relative;
  z-index: 2;
  padding-bottom: toRem(180px);
  overflow: hidden;

  @media (max-width: 768px) {
    padding-bottom: 60px;
  }

  &__new-economy {
    width: toRem(750px);
    height: toRem(75px);
    background: linear-gradient(0deg, #77509D, #B66DFD);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255,255,255,.6);
    font-family: 'Benzin';
    font-size: toRem(25px);
    font-weight: 900;
    line-height: toRem(48.815px); /* 195.258% */
    border-radius: toRem(75px);
    position: absolute;
    bottom: toRem(25px);
    left: 50%;
    translate: -50% 0;
    z-index: 2;
    text-transform: uppercase;

    @media (max-width: 768px) {
      width: 70%;
      font-size: 24px !important;
      height: 45px;
      bottom: 0px;
    }

    @media (max-width: 420px) {
      font-size: 18px !important;
    }

    @media (max-width: 360px) {
      font-size: 14px !important;
    }
  }

  &:after {
    content: '';
    display: block;
    width: calc(100% - ((100% - toRem(1300px)) / 2));
    position: absolute;
    top: auto;
    bottom: toRem(65px);
    left: 0;
    height: toRem(120px);
    background: #F9F4FF;
    border-radius: 0 0 toRem(33px) 0;

    @media (max-width: 768px) {
      width: 90%;
      left: 5%;
      height: 45px;
      bottom: 20px;
      border-radius: 0 0 33px 33px;
    }
  }

  &__logo {
    position: absolute;
    bottom: toRem(-20px);
    right: toRem(-70px);
    z-index: -2;
    rotate: 120deg;
    opacity: .5;

    svg {
      width: toRem(250px);
      height: auto;

      path {
        fill: #B66DFD;
      }
    }
  }
}

.rat-race {
  background: #F9F4FF;
  position: relative;
  overflow: hidden;
  z-index: 2;
  
  &__plus {
    position: absolute;
    width: toRem(80px);
    height: toRem(80px);
    opacity: .2;
    svg {
      position: absolute;
      width: toRem(80px);
      height: toRem(80px);

      &:nth-child(2) {
        translate: 0 toRem(-15px);
      }
    }
    &--first {
      top: 10%;
      left: 8%;
      rotate: 21deg;
    }
    &--second {
      top: 8%;
      left: 2%;
      scale: 0.4;
      rotate: 15deg;
    }
    &--thrid {
      top: 18%;
      left: 0%;
      scale: 0.2;
      rotate: 5deg;

      svg {
        animation: svgRotate 4s ease-in-out infinite;
      }

      @keyframes svgRotate {
        0% {rotate: 5;}
        50% {rotate: 15;}
        100% {rotate: 5;}
      }
    }
  }
  
  &__title {
    position: relative;
    text-align: center;
    font-family: 'Benzin';
    font-size: toRem(75px);
    font-style: normal;
    font-weight: 900;
    line-height: 115%; /* 72.15px */
    text-transform: uppercase;
    width: max-content;
    margin: auto;
    z-index: 2;

    @media (max-width: 1024px) {
      width: min-content;
      font-size: 64px;
      line-height: 110%;
    }

    @media (max-width: 768px) {
      font-size: 48px;
    }

    @media (max-width: 420px) {
      font-size: 35px;
    }

    span {
      background: linear-gradient(0deg, #77509D -13.33%, #B66DFD 88.89%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    svg {
      position: absolute;
      width: 100%;
      height: auto !important;
      bottom: toRem(0px);
      left: 0;
      z-index: -1;
    }
  }

  &__subtitle {
    color: #77509D;
    text-align: center;
    font-family: 'Benzin';
    font-size: toRem(25.855px);
    font-weight: 900;
    line-height: 100%;
    text-transform: uppercase;
    margin: toRem(23px) 0 toRem(26px);

    @media (max-width: 1024px) {
      font-size: 22px;
      line-height: 110%;
    }

    @media (max-width: 768px) {
      font-size: 20px;
    }

    @media (max-width: 420px) {
      font-size: 18px;
    }
  }

  &__row {
    display: flex;
    gap: toRem(65px);

    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 10px;
    }
  }

  &__roberto {
    &--picture {
      width: toRem(433px);
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      box-shadow: toRem(0px) toRem(15px) toRem(40px) toRem(0px) rgba(42, 0, 95, 0.13);
      position: relative;
      z-index: 2;
      margin: auto;

      @media (max-width: 460px) {
        width: 80%;
      }

      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &--name {
      border-radius: toRem(50px);
      padding: toRem(8px) toRem(25px) toRem(8px);
      white-space: nowrap;
      background: #77509D;
      color: #FFF;
      text-align: center;
      font-family: 'Benzin';
      font-weight: 900;
      line-height: 100%;
      width: max-content;
      margin: toRem(-35px) auto toRem(12px);
      position: relative;
      z-index: 2;
      text-transform: uppercase;
      font-size: toRem(22px);

      @media (max-width: 420px) {
        font-size: 24px !important;
        padding: 6px 15px 6px !important;
      }
    }

    &--about {
      color: #4B3568;
      font-family: 'Benzin';
      font-size: toRem(12.263px);
      font-weight: 900;
      line-height: toRem(20.44px); /* 166.677% */
      text-transform: uppercase;
      text-align: center;
    }

    &--description {
      color: #4B3568;
      text-align: center;
      font-size: toRem(15.094px);
      font-weight: 400;
      line-height: toRem(15px); /* 138.831% */
    }
  }

  &__block-holder {
    position: relative;
    z-index: 2;

    & > svg {
      position: absolute;
      top: toRem(-100px);
      rotate: -80deg;
      right: 0;
      width: toRem(182px);
      height: auto;
      opacity: .5;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  &__block {
    left: toRem(40px);
    padding: toRem(49px) toRem(37px) toRem(40px) toRem(15px);
    position: relative;
    width: calc(100% + ((100vw - toRem(1260px)) / 2));
    display: flex;
    gap: toRem(13px);
    margin-top: toRem(20px);
    z-index: 2;

    @media (max-width: 1024px) {
      width: 100%;
      justify-content: center;
    }

    @media (max-width: 650px) {
      padding: 26px 40px;
      left: 15px;
    }

    @media (max-width: 460px) {
      padding: 26px 30px;
    }

    &:before {
      content: '';
      display: block;
      background: #F9F4FF;
      border-radius: toRem(40px);
      transform: skewX(10deg);
      position: absolute;
      top: 0;
      left: toRem(-65px);
      bottom: 0;
      width: 80vw;
      z-index: -1;
      box-shadow: toRem(0px) toRem(15px) toRem(45px) rgba(42, 0, 95, 0.13);

      @media (max-width: 1024px) {
        width: 120vw;
        left: 0;
      }

      @media (max-width: 460px) {
        transform: skewX(5deg);
        left: -2vw;
      }
    }

    &--title {
      color: #4B3568;
      text-align: center;
      font-family: 'Benzin';
      font-size: toRem(81.517px);
      font-weight: 900;
      line-height: 100%;
      text-transform: uppercase;  
      white-space: nowrap;
      position: relative;
      width: max-content;
      margin: auto;

      @media (max-width: 900px) {
        font-size: 64px;
        text-indent: -100px;
      }

      @media (max-width: 768px) {
        font-size: 55px;
      }

      @media (max-width: 650px) {
        font-size: 45px;
        text-align: left;
        margin: 0;
        text-indent: 0px;
      }

      @media (max-width: 460px) {
        font-size: 38px;
      }
    }

    &--subtitle {
      color: #4B3568;
      text-align: center;
      font-family: 'Benzin';
      font-size: toRem(46.053px);
      font-style: normal;
      font-weight: 900;
      line-height: 100%; /* 44.303px */
      text-transform: uppercase;
      opacity: .6;
      white-space: nowrap;
      margin-top: toRem(-8px);

      &:not(.russian_) {
        display: none !important;
      }

      @media (max-width: 900px) {
        font-size: 36.5px;
        text-indent: -100px;
      }

      @media (max-width: 768px) {
        font-size: 31px;
      }

      @media (max-width: 650px) {
        font-size: 25.9px;
        line-height: 110%;
        text-align: left;
        text-indent: 0px;
      }

      @media (max-width: 460px) {
        font-size: 21.5px;
        line-height: 130%;
      }
    }

    &--text {
      color: #4B3568;
      text-align: center;
      font-size: toRem(16.799px);
      font-weight: 500;
      line-height: toRem(23.322px); /* 138.831% */ 
      margin-top: toRem(15px);

      b {
        font-weight: 900;
      }

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 135%;
      }

      @media (max-width: 650px) {
        text-align: left;

        br {
          display: none;
        }
      }
    }

    &--picture {
      width: toRem(132px);
      position: absolute;
      right: toRem(-140px);
      top: toRem(-5px);

      @media (max-width: 900px) {
        width: 100px;
        right: -110px;
      }

      @media (max-width: 768px) {
        width: 90px;
        right: -100px;
        top: -8px;
      }

      @media (max-width: 650px) {
        width: 80px;
        right: -100px;
        top: -12px;
      }

      @media (max-width: 460px) {
        width: 70px;
        right: -80px;
        top: -12px;
      }

      @media (max-width: 400px) {
        display: none !important;
      }

      &:after {
        content: '';
        display: block;
        position: relative;
        padding-bottom: 100%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__solution {
    margin-top: toRem(33px);

    &--title {
      color: #4B3568;
      text-align: center;
      font-size: toRem(15.321px);
      font-weight: 900;
      line-height: toRem(21.27px);
      opacity: 0.6;
    }
    &--name {
      border-radius: toRem(50.806px);
      background: #FAAE54;
      color: #77509D;
      text-align: center;
      font-family: 'Benzin';
      font-weight: 900;
      line-height: 100%;
      width: max-content;
      margin: toRem(11px) auto toRem(8px);
      padding: toRem(7px) toRem(40px);
      font-size: toRem(24px);

      @media (max-width: 640px) {
        padding: toRem(4px) toRem(15px) toRem(4px) !important;
      } 

      @media (max-width: 400px) {
        font-size: 16px !important;
      }

      @media (max-width: 360px) {
        font-size: 14px !important;
      }
    }
    &--description {
      color: #77509D;
      text-align: center;
      font-family: 'Benzin';
      font-size: toRem(22.445px);
      font-weight: 400;
      line-height: toRem(27.898px); /* 124.294% */
      text-transform: uppercase;
      opacity: 0.9;
      
      svg {
        height: toRem(28px);
        width: auto;
        vertical-align: middle;
        translate: 0% -10%;

        @media (max-width: 640px) {
          height: 24px;
        }

        @media (max-width: 400px) {
          height: 20px;
        }
      }

      @media (max-width: 640px) {
        font-size: 20px !important;
      }

      @media (max-width: 640px) {
        font-size: 18px !important;
      }

      @media (max-width: 420px) {
        font-size: 14px !important;
        line-height: 120%;
      }

      @media (max-width: 360px) {
        font-size: 13px !important;
      }
    }
  }

  &__scroll-down {
    display: flex;
    align-items: flex-start;
    position: absolute;
    bottom: toRem(20px);
    left: calc((100vw - toRem(1260px)) / 4);
    z-index: 3;

    span {
      writing-mode: vertical-lr;
      white-space: nowrap;
      rotate: 180deg;
      opacity: .2;
      color: #000;
      font-size: toRem(16.876px);
      font-weight: 400;
      line-height: 146.687%; /* 24.755px */
    }

    svg, img {
      width: auto;
      height: toRem(108px);
      color: #000;

      path {
        fill: #000;
      }
    }
  }
}