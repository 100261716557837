.consumer-pattern {
  background: #F7F1FF;
  position: relative;

  &__logos {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    & > svg {
      opacity: .5;
      &:first-child {
        position: absolute;
        top: 50%;
        right: 1%;
        width: toRem(260px);
        height: auto;
        translate: 0 -90%;
        rotate: -80deg;
      }
      &:last-child {
        position: absolute;
        top: 50%;
        left: 7%;
        width: toRem(200px);
        height: auto;
        translate: 0 -90%;
        rotate: -170deg;
      }
    }
  }

  &__holder {
    position: relative;
    top: toRem(-75px);
    margin: 0 auto toRem(-120px);
    z-index: 3;
    max-width: toRem(1030px);
    width: 100%;

    @media (max-width: 1024px) {
      position: relative;
    }
  }
  
  &__box {
    background: #F7F1FF;
    border-radius: toRem(26px);
    box-shadow: toRem(0px) toRem(11.07472px) toRem(44.29887px) toRem(0px) rgba(42, 0, 95, 0.13);
    padding: 0 toRem(114px) toRem(55px);
    width: 100%;

    @media (max-width: 1024px) {
      padding: 0 24px 34px;
    }
  }

  &__top-title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    height: toRem(75px);
    border-radius: toRem(75px);
    background: linear-gradient(0deg, #77509D -13.33%, #B66DFD 88.89%);
    color: rgba(255, 255, 255, 0.6);
    font-family: 'Benzin';
    font-weight: 900;
    line-height: 100%;
    font-size: toRem(25px);
    top: calc(toRem(-75px) / 2);
    margin: auto;
    margin-bottom: calc(toRem(-105px) / 2);
    white-space: nowrap;
    width: max-content;
    padding: 0 toRem(90px) toRem(1px);

    @media (max-width: 1024px) {
      font-size: 24px !important;
      width: 100%;
      padding: 0 20px 0 !important;
      max-width: 620px;
      margin: auto;
      margin-bottom: calc(toRem(-75px) / 1);
    }

    @media (max-width: 640px) {
      font-size: 18px !important;
      height: 50px;
      top: calc(toRem(-50px) / 2);
      margin-bottom: -50px;
    }

    @media (max-width: 460px) {
      font-size: 16px !important;
    }

    @media (max-width: 420px) {
      font-size: 13px !important;
      height: 40px;
      top: calc(toRem(-40px) / 2);
      margin: 0 -10px -40px;
      width: calc(100% + 20px);
    }
  }

  &__title {
    position: relative;
    width: max-content;
    z-index: 2;
    margin: auto;
    margin-top: toRem(30px);

    span {
      font-family: 'Benzin';
      font-size: toRem(108px);
      font-weight: 900;
      text-transform: lowercase;
      font-variant: all-small-caps;

      background: linear-gradient(0deg, #77509D -13.33%, #B66DFD 88.89%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      line-height: 120%;

      @media (max-width: 768px) {
        font-size: 90px;
      }

      @media (max-width: 640px) {
        font-size: 70px;
      }

      @media (max-width: 520px) {
        font-size: 58px;
      }

      @media (max-width: 420px) {
        font-size: 40px;
      }
    }

    svg {
      width: 100%;
      height: auto;
      position: absolute;
      bottom: toRem(20px);
      left: 0;
      z-index: -1;

      @media (max-width: 768px) {
        bottom: 15px;
      }

      @media (max-width: 520px) {
        bottom: 10px;
      }
    }

    @media (max-width: 1024px) {
      margin: 35px auto 0;
    }
  }

  &__text {
    color: #4B3568;
    font-size: toRem(25.733px);
    font-weight: 300;
    line-height: 146.687%;
    opacity: 0.85;
    text-align: center;

    b {
      font-weight: 700;
    }

    @media (max-width: 1024px) {
      text-align: center;
    }

    @media (max-width: 640px) {
      font-size: 20px;
      margin-top: 20px;

      br {
        display: none;
      }
    }

    @media (max-width: 640px) {
      font-size: 18px;
      margin-top: 20px;
    }
  }

  &__logo {
    position: absolute;
    top: 60%;
    translate: 0 -50%;
    right: toRem(-130px);
    rotate: -102deg;
    z-index: -1;

    svg {
      width: toRem(240px);
      height: auto;
      opacity: .6;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }
}