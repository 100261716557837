@mixin glitchCopy { 
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitch {
	position: relative;

  &::before {
    @include glitchCopy;

    left: 2px;
    text-shadow: -2px 0 #ff00c1;
    clip: rect(44px, 450px, 56px, 0);
    animation: glitch-anim .2s infinite linear alternate-reverse;
    opacity: 0;
  }

	&::after {
		@include glitchCopy;
  
		left: -2px;
		text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
		animation: glitch-anim2 .2s infinite linear alternate-reverse;
    opacity: 0;
	}
}

.once {
  animation-iteration-count: 1 !important;
  
  &::after,
  &::before {
    animation-iteration-count: 1 !important;
  }
}

@keyframes glitch-anim {
  $steps: 50;
  @for $i from 0 through $steps {
    #{percentage($i*calc(1/$steps))} {
      clip: rect(random(100)+px, 9999px, random(100)+px, 0);
      opacity: 1;
    }
  }
}

@keyframes glitch-anim2 {
  $steps: 50;
  @for $i from 0 through $steps {
    #{percentage($i*calc(1/$steps))} {
      clip: rect(random(100)+px, 9999px, random(100)+px, 0);
      opacity: 1;
    }
  }
}