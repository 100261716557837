body {
  background-color: #38006F;
  background-position: top;
  background-repeat: repeat;
  background-size: toRem(32px);
  background-attachment: fixed;
}

.page-loader {
  background: linear-gradient(180deg, #3E107A, #6C20C8);
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  svg, img {
    width: toRem(160px);
    height: auto;
  }
}

.menu {
  padding: toRem(30px) 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;

  @media (max-width: 640px) {
    padding: 50px 0;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__side {
    display: flex;
    align-items: center;

    @media (max-width: 640px) {
      width: 100%;
    }

    &--left {
      gap: toRem(17px);

      @media (max-width: 640px) {
        // width: 0;
      }
    }
  }

  &__back {
    width: toRem(29px);
    height: toRem(29px);
    background: #FAAE54;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg, img {
      width: toRem(10px);
      height: auto;
      transform: translateX(toRem(-1px));
      object-fit: contain;
    }
  }

  &__logo {
    margin: 0 toRem(70px) 0 toRem(130px);
    z-index: 10;
    transition: 0.6s cubic-bezier(0.77, 0, 0.18, 1) translate;
    transition-delay: 0.3s;
    display: flex;
    align-items: center;
    gap: toRem(30px);

    @media (max-width: 640px) {
      width: 100%;
      // justify-content: center;
      margin: 0;
    }

    svg, img {
      width: toRem(90px);
      height: auto;
      object-fit: contain;

      @media (max-width: 420px) {
        width: 70px;
      }
    }

    @media (min-width: 640px) {
      &--open {
        translate: toRem(-109px) 0 0;
        transition-delay: 0.5s;
      }
    }

    span {
      display: flex;
      gap: toRem(30px);
      color: #F0DFFF;
      font-size: toRem(13px);
      font-weight: 300;
      opacity: .6;
      align-items: center;

      &:before {
        content: '';
        display: block;
        opacity: 0.1;
        background: #FFF;
        width: toRem(1px);
        height: 44px;
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }
  }

  &__elements {
    display: flex;
    align-items: center;
    gap: toRem(20px);
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      a {
        color: rgba(255,255,255,.7);
        transition: .2s ease color;
        white-space: nowrap;

        font-size: toRem(13px);
        font-weight: 400;

        &:hover {
          color: rgba(255,255,255,1);
        }
      }
    }
  }

  &__white-paper {
    display: flex;
    align-items: center;
    gap: toRem(4.8px);
    white-space: nowrap;

    color: rgba(255,255,255,.88);
    font-size: toRem(13px);
    font-weight: 400;
    text-decoration: underline 1px solid rgba(255,255,255,.4) !important;
    text-decoration-skip-ink: none;
    text-underline-offset: toRem(2px);
    transition: .2s ease color;
    // margin-right: toRem(50px);

    @media (max-width: 900px) {
      display: none;
    }

    &:hover {
      color: rgba(255,255,255,1);
    }

    svg, img {
      width: toRem(12px);
      transform: translateY(toRem(1px));
    }
  }

  &__smart-chain {
    display: flex;
    align-items: center;
    gap: toRem(5px);
    white-space: nowrap;
    margin-right: toRem(13px);

    color: rgba(255,255,255,.8);
    font-size: toRem(9.5px);
    font-weight: 400;

    @media (max-width: 900px) {
      display: none;
    }

    svg, img {
      width: toRem(15px);
      transform: translateY(toRem(1px));
    }
  }

  &__sign {
    display: flex;
    align-items: center;
    gap: toRem(14px);
    white-space: nowrap;
    // margin-right: toRem(10px);

    @media (max-width: 640px) {
      // display: none;
    }
    
    &--in {
      color: rgb(240, 223, 255);
      font-size: toRem(10px);
      font-weight: 700;
      line-height: 150%; /* 15px */
      text-decoration: underline 1px solid rgba(240, 223, 255,.4) !important;
      text-decoration-skip-ink: none;
      text-underline-offset: toRem(2px);
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: toRem(3px);

      svg, img {
        width: toRem(9px);
      }
    }

    &--up {
      display: inline-flex !important;
      align-items: center;
      font-weight: 900;
      text-transform: uppercase;
      font-family: 'Benzin' !important;

      @media (max-width: 420px) {
        height: 28px !important;
        width: auto !important;
        padding: 0 10px !important;
        min-width: auto !important;
        font-size: 13px !important;
      }
    }
  }

  &__toggle {
    position: absolute;
    top: toRem(20px);
    left: toRem(20px);
    z-index: 20;

    @media (max-width: 640px) {
      top: 40px;
    }

    &--box {
      width: toRem(60px);
      height: toRem(60px);
      background: rgba(250, 174, 84, 0.13);
      border: toRem(2px) solid rgba(250, 174, 84, 0.69);
      border-radius: toRem(5px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @media (max-width: 640px) {
        width: 36px;
        height: 36px;
      }

      &__inset {
        display: grid;
        width: max-content;
        gap: toRem(5px);
        width: toRem(30px);

        @media (max-width: 640px) {
          width: 18px;
          gap: 2.5px;
        }
      }

      span {
        height: toRem(3px);
        width: 100%;
        background: rgba(250, 174, 84, 1);
        border-radius: toRem(3px);
        display: block;
        transition: .3s ease;
        position: relative;

        @media (max-width: 640px) {
          height: 2px;
        }

        &:first-child {
          width: toRem(12px);
          transform-origin: center left;

          @media (max-width: 640px) {
            width: 9px;
          }
        }

        &:nth-child(2) {
          transform-origin: center center;
        }

        &:last-child {
          width: toRem(12px);
          transform-origin: center right;
          justify-self: flex-end;

          @media (max-width: 640px) {
            width: 9px;
          }
        }
      }

      &.this-active {
        span {
          &:first-child {
            rotate: 45deg;
            translate: 35% -55%;
          }
          &:nth-child(2) {
            rotate: -45deg;
          }
          &:last-child {
            rotate: 45deg;
            translate: -40% 100%;
          }
        }
      }
    }

    &--title {
      text-align: center;
      font-size: toRem(14px);
      margin-top: toRem(4px);
      color: white;
    }
  }
}

.language-selector {
  position: relative;
  z-index: 10;

  @media (max-width: 640px) {
    // position: absolute;
    // right: 20px;
  }

  &__current {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: toRem(3px);
    cursor: pointer;

    & > svg,
    & > img {
      width: toRem(14px);
      height: toRem(14px);
      border-radius: 50%;
    }

    span {
      color: #A7A7A7;
      font-size: toRem(9.5px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      gap: toRem(3px);

      & > svg,
      & > img {
        width: toRem(6.2px);
      }
    }
  }

  &__dropdown {
    position: absolute;
    background: #F9F4FF;
    border-radius: toRem(20px);
    width: toRem(150px);
    padding: toRem(20px) toRem(20px) toRem(5px);
    top: calc(100% + toRem(15px));
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 1024px) {
      left: auto;
      right: 0;
      transform: none !important;
    }

    & > svg {
      height: toRem(5px);
      position: absolute;
      left: 50%;
      top: toRem(-4.5px);
      transform: translateX(-50%);

      path {
        fill: #F9F4FF;
      }

      @media (max-width: 1024px) {
        left: auto;
        right: 15px;
        transform: none !important;
      }
    }

    &--title {
      color: rgba(23, 12, 37, 0.6);
      font-size: toRem(14px);
      font-weight: 400;
      line-height: 107%; /* 14.98px */
      letter-spacing: toRem(2px);
    }

    &--list {
      list-style: none;
      margin: toRem(8px) 0 0;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        height: toRem(40px);
        gap: toRem(9px);

        color: #6A6A6A;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: toRem(14px);
        font-weight: 400;
        position: relative;

        cursor: pointer;

        span {
          transition: .2s ease translate;
        }

        & > svg,
        & > img {
          width: toRem(18px);
          height: toRem(18px);
          border-radius: 50%;
          transition: .2s ease translate;
        }

        &:before {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background: linear-gradient(90deg, rgba(181, 123, 255, 0.42), rgba(148, 82, 228, 1), rgba(181, 123, 255, 0.42));
          opacity: 0.1;
          position: absolute;
          top: 0;
          left: 0;
        }

        &:hover {
          svg, img {
            translate: 5px;
          }
          span {
            translate: 10px;
          }
        }
      }
    }
  }
}

i {
  &.icon {
    svg, img {
      float: left;
      height: auto;
    }
  }
}

.default-button {
  display: inline-block;
  font-family: 'Benzin';
  min-width: toRem(150px);
  height: toRem(35px);
  padding: toRem(0px) toRem(8px);
  justify-content: center;
  align-items: center;
  color: #FAAE54;
  font-family: $font-stack;
  text-align: center;
  font-size: toRem(12px);
  font-weight: 700;
  line-height: toRem(14px); /* 116.667% */
  border-radius: toRem(5px);
  background: rgba(250, 174, 84, 0.13);
  border: toRem(2px) solid rgba(250, 174, 84, 0.70);
  transition: cubic-bezier(0.77, 0, 0.18, 1) 0.6s color;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  i {
    &.icon {
      display: block;
      position: absolute;
      right: toRem(10px);
      top: 50%;
      transform: translateY(-50%);

      svg {
        path {
          transition: cubic-bezier(0.77, 0, 0.18, 1) 0.6s;
        }
      }
    }
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(250, 174, 84, 0.35);
    z-index: -1;
    border-radius: 0 0 100% 0;
    translate: -100% -100%;
    transition: cubic-bezier(0.77, 0, 0.18, 1) 0.6s;
    transition-delay: 0.2s;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(250, 174, 84, 0.35);
    z-index: -1;
    border-radius: 0 0 100% 0;
    translate: -100% -100%;
    transition: cubic-bezier(0.77, 0, 0.18, 1) 0.6s;
    transition-delay: 0.0s;
  }

  &:hover {
    color: #fff !important;
    &:before {
      translate: 0 0;
      border-radius: toRem(3px);
      transition-delay: 0.0s;
    }
    &:after {
      translate: 0 0;
      border-radius: toRem(3px);
      transition-delay: 0.2s;
    }
    i {
      &.icon {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
}

a.default-button {
  display: inline-flex !important;
  align-items: center;
}

.orange-button {
  position: relative;
  color: #FFF;
  text-align: center;
  display: inline-flex;
  font-size: toRem(22.714px);
  font-weight: 700;
  line-height: toRem(22.714px);
  border-radius: toRem(25px);
  border: none;
  padding: toRem(24px) toRem(65px) toRem(24px) toRem(28px);
  background: #FAAE54;
  box-shadow: toRem(0px) toRem(0px) toRem(50px) rgba(250, 174, 84, 0.19);
  font-family: $font-stack;

  i {
    &.icon {
      display: block;
      position: absolute;
      right: toRem(30px);
      top: 50%;
      transform: translateY(-50%);

      svg {
        height: toRem(15px);
        width: auto;

        path {
          transition: cubic-bezier(0.77, 0, 0.18, 1) 0.6s;
        }
      }
    }
  }
}

.big-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  white-space: nowrap;
  visibility: hidden;

  &__overlay-1 {
    background: #4b188d;
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 0%;
    border-radius: 0 0 100% 0;
    z-index: 2;
    transition: cubic-bezier(0.77, 0, 0.18, 1) 0.6s;
    transition-delay: 0.2s !important;
    translate: 0 -100%;
    overflow: hidden;
  }

  &__overlay-2 {
    background: #431478;
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 0%;
    border-radius: 0 0 100% 0;
    z-index: 1;
    transition: cubic-bezier(0.77, 0, 0.18, 1) 0.6s;
    transition-delay: 0.0s;
    overflow: hidden;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: url("../assets/img/menu-background.jpg");
      top: 0;
      left: 0;
      opacity: .1;
      z-index: -2;
    }
  }

  &__matrix {
    opacity: .1;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 0;
  }

  &__noise {
    opacity: .15;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 0;
  }

  &.this-active {
    visibility: visible;

    .big-menu__overlay-1 {
      border-radius: 0 0 0 0;
      width: 100%;
      height: 100%;
      transition-delay: 0.0s;
      translate: 0 0;
    }
    .big-menu__overlay-2 {
      border-radius: 0 0 0 0;
      width: 100%;
      height: 100%;
      transition-delay: 0.3s;
    }
  }

  &__content {
    position: relative;
    z-index: 3;
    width: 100%;
    max-width: toRem(1100px);

    @media (max-width: 1024px) {
      padding: 0 25px;
    }
  }

  &__wrapper {
    display: flex;
    gap: toRem(60px);

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  &__side {
    position: relative;

    &--left {
      width: 60%;
      display: flex;
      flex-direction: column;

      @media (max-width: 460px) {
        gap: 20px;
      }
    }

    &--right {
      width: 40%;
    }
  }

  &__block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &--background {
      position: absolute;
      top: calc(50% - toRem(540px) / 1.6);
      left: calc(50% - toRem(701px) / 2);
      width: toRem(500px);
      height: toRem(660px);
      background: url("../assets/img/menu-block-bg.svg") center no-repeat;
      object-fit: contain;
    }

    &--content{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__element {
    font-family: 'Benzin', sans-serif;
    font-size: toRem(56px);
    text-transform: uppercase;
    font-weight: 900;
    color: transparent;
    transition: .3s;
    position: relative;
    z-index: 2;

    @media (max-width: 1024px) {
      font-size: 42px;
    }

    @media (max-width: 768px) {
      font-size: 38px;
    }

    @media (max-width: 640px) {
      font-size: 32px;
    }

    @media (max-width: 520px) {
      font-size: 28px;
    }

    @media (max-width: 460px) {
      font-size: 26px;
    }

    @media (max-width: 400px) {
      font-size: 21px;
    }

    &:before {
      content: attr(data-text);
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #fff;
      transition: .3s;
      filter: url(#stroke-text-svg-filter);
    }

    &:after {
      content: '';
      display: block;
      width: 100vw;
      height: toRem(15px);
      position: absolute;
      left: -80vw;
      top: 50%;
      background: #FAAE54;
      z-index: -1;
      translate: -100% -50%;
      transition: 0.3s cubic-bezier(0.77, 0, 0.18, 1) translate;
      border-radius: toRem(3px);
    }

    &.--hover,
    &:hover {
      color: #fff;

      &:before {
        opacity: 0;
      }
      &:after {
        translate: 0% -50%;
      }
    }
  }

  &__main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    z-index: 4;
    transition: 0.3s cubic-bezier(0.77, 0, 0.18, 1);

    &.this-active {
      opacity: 1;
      visibility: visible;
      transition-delay: 0.6s;
    }
  }
}

.menu-block {
  position: relative;

  &__title {
    font-size: toRem(22px);
    font-family: 'Benzin';
    font-weight: 900;
    text-transform: uppercase;
    color: #fff;

    span {
      color: #FAAE54;
    }
  }

  &__description {
    margin-bottom: toRem(90px);

    &--text {
      width: 65%;
      color: #fff;
      opacity: .6;
      font-size: toRem(18px);
      font-style: normal;
      font-weight: 700;
      margin-top: toRem(15px);
      white-space: break-spaces;
      line-height: 146.687%;
    }

    &--picture {
      position: absolute;
      width: 70%;
      top: 0;
      right: -10%;

      img {
        width: 100%;
      }
    }
  }

  &__bottom {
    position: relative;
    max-width: toRem(260px);

    &--arrow {
      position: absolute;
      top: toRem(-76px);
      rotate: 90deg;
      svg {
        width: toRem(46px);
        height: auto;
        
        path {
          fill: #fff;
        }
      }
    }

    &--description {
      color: #fff;
      opacity: .6;
      font-weight: 700;
      font-family: 'Benzin';
      font-size: toRem(10px);
      text-transform: uppercase;
      text-align: center;
      margin-top: toRem(8px);
      text-shadow: toRem(0px) toRem(2px) toRem(2px) rgba(0, 0, 0, 0.25);
    }
  }

  &__button {
    width: 100%;
    height: toRem(64px);
    border-radius: toRem(5px);
    color: #FAAE54;
    font-family: 'Benzin';
    font-size: toRem(19px);
    padding-right: toRem(20px);
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: toRem(0px) toRem(4px) toRem(4px) rgba(0, 0, 0, 0.25);

    i {
      &.icon {
        right: toRem(20px);
        
        svg, img {
          height: auto;
          width: toRem(14px);

          path {
            fill: #FAAE54;
          }
        }
      }
    }
  }
}

.button-subtitle {
  color: #FFF;
  font-size: toRem(13px);
  font-weight: 300;
  line-height: 146.687%; /* 23.47px */
  opacity: 0.5;
}

.default-slick-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  position: absolute;
  top: 50%;
  left: 5px;
  translate: 0 -50%;
  z-index: 2;
  border: none;
  border-radius: 100px;
  background: #FAAE54;
  color: #fff;

  svg {
    width: 14px;
    height: auto;
    margin-top: -1px;
    margin-right: -1px;
  }

  &--next {
    right: 5px;
    left: auto;
  }

  &--prev {
    rotate: 180deg;
  }
}

.modal {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background: rgba(17, 2, 32, 0.8);

  &__content {
    min-width: toRem(320px);
    background: #F9F4FF;
    margin: auto;
    border-radius: toRem(16px);
    overflow: hidden;
  }

  &.--video {
    .modal__content {
      width: 80vw;
      aspect-ratio: 16/9;
      background: #000;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}