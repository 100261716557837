.smartcontract {
  position: relative;
  background: #F7F1FF;
  padding: toRem(160px) 0 toRem(100px);
  overflow: hidden;

  @media (max-width: 1024px) {
    padding: toRem(100px) 0 toRem(80px);
  }

  &__plus {
    position: absolute;
    width: toRem(80px);
    height: toRem(80px);
    opacity: .4;

    @media (max-width: 1024px) {
      display: none;
    }

    svg {
      position: absolute;
      width: toRem(80px);
      height: toRem(80px);

      &:nth-child(2) {
        translate: 0 toRem(-15px);
      }
    }

    &--first {
      top: 14%;
      left: 9%;
      rotate: 21deg;
    }

    &--second {
      top: 13%;
      left: 3%;
      scale: 0.4;
      rotate: 15deg;
    }

    &--thrid {
      top: 18%;
      left: 2%;
      scale: 0.2;
      rotate: 5deg;

      svg {
        animation: svgRotate 4s ease-in-out infinite;
      }

      @keyframes svgRotate {
        0% {rotate: 5;}
        50% {rotate: 15;}
        100% {rotate: 5;}
      }
    }
  }

  &__title {
    text-align: center;
    font-family: 'Benzin';
    font-size: toRem(80px);
    text-transform: lowercase;
    font-variant: all-small-caps;
    font-weight: 900;
    position: relative;
    width: max-content;
    white-space: nowrap;
    z-index: 2;
    margin: 0 auto toRem(5px);

    span {
      background: linear-gradient(0deg, #77509D -13.33%, #B66DFD 88.89%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    svg {
      position: absolute;
      bottom: toRem(15px);
      left: 0;
      width: 100%;
      height: auto;
      z-index: -1;

      @media (max-width: 768px) {
        bottom: 10px;
      }
    }

    &--picture {
      position: absolute;
      top: 50%;
      translate: 120% -50%;
      right: 0;
      width: toRem(197px);
      height: toRem(285px);
      scale: 1.1;

      svg,
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }

    @media (max-width: 768px) {
      font-size: 62px;
    }

    @media (max-width: 640px) {
      font-size: 48px;
    }

    @media (max-width: 480px) {
      font-size: 42px;
    }

    @media (max-width: 460px) {
      font-size: 38px;
    }

    @media (max-width: 380px) {
      font-size: 36px;
    }
  }

  &__subtitle {
    color: #77509D;
    text-align: center;
    font-size: toRem(20.563px);
    font-style: normal;
    font-weight: 400;
    line-height: 119.7%;

    b {
      font-weight: 700;
    }

    @media (max-width: 480px) {
      font-size: 18px;
    }

    @media (max-width: 390px) {
      font-size: 15px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 33fr);
    gap: toRem(60px) toRem(40px);
    margin-top: toRem(77px);

    @media (max-width: 1024px) {
      gap: 60px 20px;
    }

    @media (max-width: 960px) {
      gap: 60px 40px;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      margin: 70px -15px 0;
    }

    .slick-list,
    .slick-track {
      overflow: visible;
    }

    .slick-slide {
      padding: 0 25px;
    }
  }

  &__box {
    filter: drop-shadow(0px toRem(10px) toRem(30px) rgba(42, 0, 95, 0.13));

    &--inset {
      background: #F7F1FF;
      position: relative;
      z-index: 2;
      border-radius: 0 0 toRem(20px) toRem(20px);
      padding: 0 toRem(5px) toRem(40px) toRem(50px);

      &:before {
        content: '';
        display: block;
        position: absolute;
        height: toRem(80px);
        left: 0;
        width: 100%;
        background: #F7F1FF;
        transform: skewY(4deg);
        top: toRem(-30px);
        z-index: -1;
        border-radius: toRem(20px);
      }

      @media (max-width: 1024px) and (min-width: 960px) {
        padding: 0 10px 40px 30px;
      }

      @media (max-width: 390px) {
        padding: 0 10px 40px 30px;
      }
    }

    &--icon {
      margin-bottom: toRem(20px);
      position: relative;

      svg {
        width: toRem(37px);
        height: toRem(37px);
      }

      span {
        position: absolute;
        top: 0;
        left: 0;
        scale: 1.8;
        transform-origin: left center;
        opacity: .1;

        svg {
          float: left;
        }
      }
    }

    &--title {
      color: #77509D;
      font-weight: 900;
      font-size: toRem(28.32px);
      text-shadow: toRem(16px) toRem(12px) 0px rgba(119, 80, 157, 0.1);
      text-transform: uppercase;

      &:after {
        content: '';
        display: block;
        width: toRem(65px);
        height: toRem(4px);
        border-radius: toRem(4px);
        background: linear-gradient(102deg, #FAAE54 27.38%, #EFBA00 79.37%);
        display: block;
        margin: toRem(8px) 0 toRem(8px);
      }

      @media (max-width: 1024px) and (min-width: 960px) {
        font-size: 24px;
      }
    }

    &--text {
      color: rgba(75, 53, 104, 0.8);
      font-size: toRem(20.2px);
      font-weight: 400;
      line-height: 146.687%;
      
      @media (max-width: 960px) {
        max-width: toRem(240px);
      }
      
      span {
        display: inline;
        background: linear-gradient(102deg, #FAAE54 27.38%, #EFBA00 79.37%);
        padding: 0 toRem(8px);
        margin: 0 toRem(2px) 0 toRem(-8px);
        white-space: nowrap;
        border-radius: toRem(30px);
        font-weight: 900;
        line-height: 90%;
        padding-bottom: toRem(1px);

        @media (max-width: 1024px) {
          font-size: 18px;
        }
      }

      @media (max-width: 1024px) {
        font-size: 18px;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: toRem(38px);
    gap: toRem(15px);

    &--text {
      opacity: .6;
      color: #4B3568;
      font-size: toRem(18.594px);
      font-weight: 400;
      line-height: 146.687%;

      b {
        font-weight: 900;
      }
    }
  }
}