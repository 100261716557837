.programs {
  overflow: hidden;
  padding: toRem(170px) 0 toRem(200px);
  background: url('../../assets/img/programs-bg.png') center top no-repeat;
  background-size: cover;
  position: relative;

  @media (max-width: 1024px) {
    padding: 100px 0 170px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: toRem(-150px);
    left: -10%;
    width: 120%;
    height: toRem(200px);
    background: #F7F1FF;
    rotate: -2deg;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: toRem(-150px);
    left: -10%;
    width: 120%;
    height: toRem(200px);
    background: #F7F1FF;
    rotate: -2deg;
  }

  &__top-title {
    display: flex;
    align-items: center;
    justify-content: center;

    height: toRem(75px);
    border-radius: toRem(75px);
    background: linear-gradient(0deg, #77509D -13.33%, #B66DFD 88.89%);
    color: rgba(255, 255, 255, 0.6);
    font-family: 'Benzin';
    font-size: toRem(25px);
    text-transform: uppercase;
    font-weight: 900;
    line-height: 100%;
    margin-top: calc(toRem(-75px) / 2);
    white-space: nowrap;
    width: max-content;
    padding: 0 toRem(110px) toRem(1px);
    translate: -50% 50%;

    position: absolute;
    top: 0;
    left: 50%;

    @media (max-width: 1024px) {
      font-size: 22px;
    }

    @media (max-width: 960px) {
      font-size: 18px;
      padding: 0 toRem(20px) toRem(0px);
    }

    @media (max-width: 460px) {
      font-size: 14px;
      height: toRem(65px);
      margin-top: calc(toRem(-65px) / 2);
    }

    @media (max-width: 390px) {
      font-size: 13px;
      height: toRem(55px);
      margin-top: calc(toRem(-25px) / 2);
    }
  }

  &__title {
    font-family: 'Benzin';
    font-size: toRem(103.699px);
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
    text-transform: uppercase;
    position: relative;
    background: linear-gradient(88deg, #FFF 38.57%, rgba(255, 255, 255, 0.50) 98.14%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 1024px) {
      font-size: 90px;
    }

    @media (max-width: 960px) {
      font-size: 74px;
    }

    @media (max-width: 768px) {
      font-size: 65px;
    }

    @media (max-width: 640px) {
      font-size: 46px;
    }

    @media (max-width: 480px) {
      font-size: 36px;
    }

    @media (max-width: 360px) {
      font-size: 32px;
    }

    &:before {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      left: -27%;
      display: block;
      height: toRem(47px);
      border-radius: toRem(20px);
      bottom: toRem(-10px);
      background: linear-gradient(-90deg, #3E107A 0%, #6C20C8 88.89%);
      z-index: -1;
      opacity: .8;
    }
  }

  &__row {
    display: flex;
    gap: toRem(65px);
    margin-top: toRem(30px);

    @media (max-width: 1024px) {
      gap: 40px;
    }

    @media (max-width: 960px) {
      flex-direction: column;
      gap: 40px;
    }
  }

  &__cell {
    &:first-child {
      width: 48%;
    }
    width: 32%;

    @media (max-width: 1024px) {
      &:first-child {
        width: 44%;
      }
      width: 36%;
    }

    @media (max-width: 960px) {
      width: 100% !important;
    }
  }

  &__list {
    &--title {
      color: #FFF;
      font-size: toRem(18.934px);
      font-style: normal;
      font-weight: 700;
      line-height: 146.687%;
      opacity: 0.8;
      position: relative;
      width: min-content;
      white-space: nowrap;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: toRem(3px);
        bottom: 0;
        left: 0;
        border-radius: toRem(3px);
        opacity: 0.5;
        background: linear-gradient(0deg, #77509D -13.33%, #B66DFD 88.89%);
        position: absolute;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      margin: toRem(25px) 0;
      margin-left: toRem(-97px);

      @media (max-width: 1024px) {
        margin-left: -40px;
      }

      @media (max-width: 640px) {
        gap: 5px;
      }

      li {
        display: flex;
        gap: toRem(15px);
        position: relative;
        z-index: 1;
        color: #fff;
        width: max-content;

        span {
          font-family: 'Benzin';
          font-size: toRem(65px);
          font-style: normal;
          font-weight: 900;
          line-height: 100%;
          letter-spacing: toRem(4.774px);
          text-transform: uppercase;
          filter: url(#stroke-text-svg-filter);
          transition: .2s color;

          @media (max-width: 1024px) and (min-width: 960px) {
            font-size: 45px;
          }

          @media (max-width: 640px) {
            font-size: 55px;
          }

          @media (max-width: 520px) {
            font-size: 46px;
          }

          @media (max-width: 460px) {
            font-size: 38px;
          }

          @media (max-width: 390px) {
            font-size: 32px;
          }
        }

        &:after {
          content: attr(data-hover);
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;

          background: linear-gradient(102deg, #FAAE54 27.38%, #EFBA00 79.37%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          
          font-family: 'Benzin';
          font-size: toRem(65px);
          font-style: normal;
          font-weight: 900;
          line-height: 100%;
          letter-spacing: toRem(4.774px);
          text-transform: uppercase;
          width: max-content;
          opacity: 0;
          transition: .2s opacity;

          @media (max-width: 1024px) and (min-width: 960px) {
            font-size: 45px;
          }

          @media (max-width: 640px) {
            font-size: 55px;
          }

          @media (max-width: 520px) {
            font-size: 46px;
          }

          @media (max-width: 460px) {
            font-size: 38px;
          }

          @media (max-width: 390px) {
            font-size: 32px;
          }
        }

        svg {
          width: toRem(52px);
          height: auto !important;
          opacity: 0;
          translate: -100% -9%;
          transition: .2s;
          color: #FAAE54 !important;

          @media (max-width: 1024px) and (min-width: 960px) {
            width: 40px;
          }

          @media (max-width: 640px) {
            width: 30px;
          }
        }

        &:before {
          content: '';
          display: block;
          width: 120%;
          right: 20%;
          border-radius: 18.849px;
          opacity: 0.5;
          background: linear-gradient(0deg, #3E107A 0%, #6C20C8 88.89%);
          top: 50%;
          position: absolute;
          height: toRem(28px);
          z-index: -1;
          opacity: 0;
          translate: -100% -60%;
          transition: .2s translate;

          @media (max-width: 640px) {
            height: toRem(18px);
          }
        }

        &.--hover {
          filter: none !important;
          color: transparent;
          &:after {
            opacity: 1;
          }
          svg {
            translate: 0% -9%;
            opacity: 1;
          }
          &:before {
            opacity: 1;
            translate: 0% -60%;
          }
        }
      }
    }
  }

  &__video {
    display: flex;
    align-items: center;
    gap: toRem(15px);

    &--button {
      mix-blend-mode: hard-light;
      svg {
        height: toRem(83px);
        width: auto;
      }
    }

    &--text {
      font-family: 'Benzin';
      font-weight: 900;
      color: #fff;
      filter: url(#stroke-text-svg-filter);
      letter-spacing: toRem(1.543px);
      text-transform: uppercase;
      font-size: toRem(22.047px);
      line-height: 110%;
      opacity: .8;
    }
  }
}

.programs__block {
  background: linear-gradient(1turn,rgba(3,0,91,.6) -13.33%,rgba(125, 0, 227, 0.9) 88.89%);
  padding: 0 toRem(50px) toRem(17px);
  position: relative;
  border-radius: toRem(30px);
  margin: toRem(20px) auto 0;

  max-width: toRem(420px);

  @media (max-width: 390px) {
    padding: 0 toRem(30px) toRem(17px);
  }

  &--header {
    border-radius: toRem(33.135px);
    background: #B66DFD;
    box-shadow: toRem(0px) toRem(2.97108px) toRem(2.97108px) toRem(0px) rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: toRem(12px);
    color: #fff;
    font-family: 'Benzin';
    height: toRem(50px);
    position: relative;
    top: toRem(-25px);
    margin-bottom: toRem(-12px);

    font-weight: 900;
    line-height: 100%;
    width: 100%;
    font-size: toRem(21px);
    text-transform: uppercase;

    svg, img {
      height: toRem(25px);
    }
  }

  &--number {
    position: absolute;
    top: 0;
    right: 0%;
    translate: 120% -17%;

    text-align: center;
    font-size: toRem(225.689px);
    font-weight: 900;
    line-height: 100%;
    text-transform: uppercase;

    font-family: 'Benzin';
    background: linear-gradient(1turn,rgba(3,0,91,.6) -13.33%,rgba(125, 0, 227, 0.9) 88.89%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 640px) {
      display: none;
    } 
  }

  &--levels {
    display: flex;
    gap: toRem(2.5px);
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      width: 100%;
      text-align: center;
      color: #FFF;
      text-indent: toRem(3px);
      font-weight: 500;
      letter-spacing: toRem(2px);
      border: 1px solid #fff;
      border-radius: toRem(20px);
      padding: toRem(1px) toRem(0px);
      opacity: .7;
      font-size: toRem(9.5px);
      text-transform: uppercase;
      flex: 1;
    }
  }

  &--desc {
    padding: toRem(30px) 0 toRem(15px);

    color: #FFF;
    font-size: toRem(20px);
    font-style: normal;
    font-weight: 300;
    line-height: 100.8%; /* 22.262px */
    letter-spacing: toRem(0.773px);

    opacity: 0.8;

    p {
      margin: 0;

      &.--transparent {
        margin-top: toRem(13px);
        font-size: toRem(18px);
        opacity: .7; 
      }

      b {
        font-weight: 900;
      }
    }

    &:after {
      content: '';
      display: block;
      width: toRem(130px);
      height: toRem(3px);
      border-radius: toRem(3px);
      opacity: 0.5;
      background: linear-gradient(0deg, #77509D -13.33%, #B66DFD 88.89%);
      margin-top: toRem(14px);
    }
  }

  &--result {
    position: relative;
    z-index: 2;
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      color: #FFF;
      font-size: toRem(18px);
      font-style: normal;
      font-weight: 300;
      line-height: 100%;
      letter-spacing: toRem(0.705px);
      opacity: 0.5;
      margin-bottom: toRem(12px);
    }

    &__numbers {
      display: flex;
      gap: toRem(10px);
      align-items: center;

      svg {
        height: toRem(14px);
        width: auto;
        color: #fff;
      }
    }

    &__from {
      color: #FFF;
      font-size: toRem(26px);
      font-weight: 900;
      border: 1px solid rgba(255,255,255,.6);
    }

    &__to {
      color: #45108A;
      font-size: toRem(26px);
      font-weight: 900;
      background: #FAAE54;
      min-width: toRem(130px);
      justify-content: center;
    }

    &__from,
    &__to {
      height: toRem(49px);
      display: flex;
      align-items: center;
      padding: toRem(2px) toRem(15px);
      border-radius: toRem(50px);
      position: relative;

      &:before {
        z-index: -1;
        position: absolute;
        content: '';
        border: 1px solid #fff;
        display: block;
        top: toRem(-3px);
        left: toRem(-3px);
        bottom: toRem(-3px);
        right: toRem(-3px);
        border-radius: toRem(50px);
        opacity: .2;
      }
    }

    &__picture {
      position: absolute;
      z-index: 2;
      width: toRem(250px);
      bottom: toRem(-30px);
      right: toRem(-90px);
      
      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &.--launch {
        width: toRem(340px);
        right: toRem(-140px);

        @media (max-width: 425px) {
          width: 260px;
          right: -40px;
        }

        @media (max-width: 390px) {
          width: 240px;
          right: -40px;
        }
      }
      &.--device {
        @media (max-width: 426px) {
          width: 220px;
          right: -20px;
          top: 40px;
        }
        @media (max-width: 390px) {
          width: 200px;
          right: -20px;
          top: 40px;
        }
      }
      &.--forsage {
        @media (max-width: 420px) {
          width: 220px;
          right: -20px;
          top: 60px;
        }
        @media (max-width: 390px) {
          width: 200px;
          right: -20px;
          top: 60px;
        }
      }
      &.--property {
        @media (max-width: 420px) {
          width: 180px;
          right: -10px;
          top: 40px;
        }
        @media (max-width: 390px) {
          width: 160px;
          right: -10px;
          top: 40px;
        }
      }
    }
  }

  &--footer {
    margin-top: toRem(10px);
    position: relative;
    z-index: 3;
    padding-left: toRem(50px);
    width: max-content;

    @media (max-width: 960px) {
      margin: 10px auto 0;
      padding-left: 0;
    }
  }

  &--button {
    width: toRem(246px);
    height: toRem(58px);
    border-radius: toRem(5px);
    color: #FAAE54;
    font-family: 'Benzin';
    font-size: toRem(19px);
    padding-right: toRem(20px);
    font-weight: 900;

    i {
      &.icon {
        right: toRem(20px);
        
        svg, img {
          height: auto;
          width: toRem(14px);

          path {
            fill: #FAAE54;
          }
        }
      }
    }
  }
}