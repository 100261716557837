.career-block {
  background: #2B0962;
  padding-top: toRem(450px);
  position: relative;

  @media (max-width: 960px) {
    padding-top: 120px;
  }

  &__bg {
    background: url('../../assets/img/career-bg.png') no-repeat top center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: toRem(600px);
    z-index: 0;

    @media (max-width: 1024px) {
      opacity: .2;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: toRem(105px);
      background: linear-gradient(180deg, transparent, #2B0962);
    }
  
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: toRem(395px);
      background: linear-gradient(0deg, transparent, #38006F);
    }
  }

  &__grid { 
    margin-bottom: toRem(0px);
    padding-bottom: toRem(40px);
    display: grid;
    grid-template-areas: 
      "main stats"
      "footer footer";
    // grid-template-columns: 70fr 30fr;
    grid-template-columns: 1fr;
    gap: 0 toRem(180px);
    position: relative;
    z-index: 2;

    .slick-slide {
      display: flex;
    }

    @media (max-width: 520px) {
      .default-slick-arrow {
        &--prev {
          left: calc((50% - (38px) / 2) - (toRem(225.279px) / 2))
        }
        &--next {
          right: calc((50% - (38px) / 2) - (toRem(225.279px) / 2))
        }
      }
    }

    @media (max-width: 960px) {
      grid-template-areas: 
        "main"
        "footer"
        "stats";
      grid-template-columns: 100%;
      padding-bottom: 100px;
    }

    &--main {
      position: relative;
      z-index: 2;
      grid-area: main;
    }

    &--stats {
      grid-area: stats;
      display: flex;
      flex-direction: column;
      gap: toRem(40px);

      @media (max-width: 960px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
      
      @media (max-width: 520px) {
        gap: 10px;
      }
    }

    &--footer {
      grid-area: footer;
      margin-top: toRem(82px);

      @media (max-width: 960px) {
        margin: 0px 0 60px;
      }
    }
  }

  &__top-title {
    font-family: 'Benzin';
    position: relative;
    z-index: 2;
    width: max-content;
    margin-bottom: toRem(50px);

    & > span {
      font-size: toRem(82px);
      font-weight: 900;
      line-height: 100%; /* 67.725px */
      text-transform: uppercase;
      background: linear-gradient(88deg, #FFF 38.57%, rgba(255, 255, 255, 0.50) 98.14%);
      z-index: 2;
      position: relative;
  
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (max-width: 768px) {
        font-size: 76px;
      }

      @media (max-width: 690px) {
        font-size: 68px;
      }

      @media (max-width: 640px) {
        font-size: 50px;
      }

      @media (max-width: 460px) {
        font-size: 42px;
      }

      @media (max-width: 390px) {
        font-size: 34px;
      }
    }

    &:after {
      content: '';
      width: 57vw;
      height: toRem(50px);
      border-radius: toRem(21.363px);
      opacity: 0.5;
      position: absolute;
      background: linear-gradient(-90deg,rgba(3,0,91,.6) -13.33%,rgba(125, 0, 227, 0.9) 88.89%);
      right: 10%;
      z-index: -1;
      bottom: toRem(10px);

      @media (max-width: 768px) {
        left: -25px;
        width: 98%;
        height: 34px;
        bottom: -10px;
      }
    }
  }

  &__title {
    color: #FFF;
    font-size: toRem(24px);
    font-weight: 900;
    margin-bottom: toRem(5px);

    @media (max-width: 768px) {
      font-size: 19px;
    }
  }

  &__text {
    color: #FFF;
    font-size: toRem(20.34px);
    font-weight: 400;
    line-height: 194.3%;
    margin-bottom: toRem(43px);
    // padding-left: toRem(70px);
    // position: relative;
    max-width: toRem(720px);

    @media (max-width: 1024px) {
      padding-left: 0;
      max-width: 100%;
    }

    @media (max-width: 768px) {
      font-size: 16px;
    }

    & > svg {
      width: toRem(105px);
      height: auto;
      position: absolute;
      top: 40%;
      right: 2%;

      @media (max-width: 1024px) {
        display: none;
      }
    }

    b {
      font-weight: 700;
    }

    p {
      margin-bottom: 0;
      &:not(:last-child) {
        margin-bottom: toRem(16px);
      }
    }
  }

  &__box {
    background: linear-gradient(180deg, #A71EFE -13.33%, #6916D0 88.89%);
    box-shadow: toRem(0px) toRem(4.37436px) toRem(65.61539px) toRem(10.9359px) rgba(73, 0, 143, 0.29);
    padding: toRem(9px);
    border-radius: toRem(21.872px);
    max-width: toRem(225.279px);
    width: 100%;

    @media (max-width: 768px) {
      margin: auto;
    }

    &--inset {
      border-radius: toRem(18px);
      border: toRem(1px) solid rgba(255, 255, 255, 0.1);
      padding: toRem(33px) toRem(5px) toRem(27px) toRem(18px);
    }

    &--icon {
      margin-bottom: toRem(20px);
      svg {
        height: toRem(60px);
        width: auto;
      }
    }

    &--title {
      color: rgba(255, 255, 255, 0.9);
      font-size: toRem(21px);
      font-weight: 900;
      line-height: 146.687%;
      margin-bottom: toRem(13px);
      opacity: .9;
      text-transform: uppercase;
    }

    &--text {
      color: #FFF;
      font-size: toRem(15.31px);
      font-weight: 400;
      line-height: 146.687%;
      opacity: .9;
    }
  }

  &__box-grid {
    display: grid;
    // grid-template-columns: 12fr 19fr 13fr;
    grid-template-columns: 1fr;

    @media (max-width: 960px) {
      grid-template-columns: repeat(3, 1fr);
    }

    &--wrapper {
      display: flex;
      justify-content: flex-start;
      translate: 0 40%;

      @media (max-width: 960px) {
        justify-content: center;
        translate: 0;
      }

      &:first-child {
        justify-content: flex-start;
        translate: 0 -90%;

        @media (max-width: 960px) {
          justify-content: center;
          translate: 0;
        }
      }
    }
  }

  &__stat-box {
    padding-top: toRem(29px);
    position: relative;
      
    @media (max-width: 390px) {
      padding-top: 20px;
    }

    @media (max-width: 960px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    &--title {
      color: #FFF;
      font-size: toRem(33.313px);
      font-weight: 900;
      text-transform: uppercase;
      margin-bottom: toRem(11px);
      
      @media (max-width: 960px) {
        font-size: 24px;
      }
      
      @media (max-width: 640px) {
        font-size: 16px;
      }
      
      @media (max-width: 520px) {
        font-size: 14px;
      }
      
      @media (max-width: 390px) {
        font-size: 12px;
        margin-bottom: 5px;
      }
      
      @media (max-width: 340px) {
        font-size: 10px;
      }
    }

    &--num {
      color: #F0DFFF;
      text-shadow: toRem(0px) toRem(4.42378044128418px) toRem(4.42378044128418px) rgba(0, 0, 0, 0.25);
      font-size: toRem(75.005px);
      font-weight: 900;
      line-height: 96.3%; /* 72.23px */
      text-transform: uppercase;
      
      @media (max-width: 960px) {
        font-size: 64px;
      }
      
      @media (max-width: 640px) {
        font-size: 48px;
      }
      
      @media (max-width: 390px) {
        font-size: 32px;
      }
    }
    
    &:before {
      height: toRem(9px);
      width: 100%;
      background: #FAAE54;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      max-width: toRem(190px);
      border-radius: toRem(9px);

      @media (max-width: 960px) {
        left: 50%;
        translate: -50% 0;
      }
      
      @media (max-width: 640px) {
        height: 5px;
      }
    }
  }

  &__footer {
    padding-bottom: toRem(120px);
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: toRem(43px);
    gap: toRem(15px);

    @media (max-width: 1024px) {
      padding-bottom: 60px;
    }

    &--text {
      color: #FFF;
      font-size: toRem(18.594px);
      font-weight: 400;
      line-height: 146.687%;
      opacity: .6;

      b {
        font-weight: 900;
      }
    }
  }
}

.num-boxes {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: toRem(18px);
  align-items: flex-end;
  width: 94%;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 640px) {
    gap: 38px;
  }  

  &__block {
    border-radius: toRem(61px);
    background: linear-gradient(360deg, rgba(3, 0, 91, 0.2) -13.33%, rgba(125, 0, 227, 0.6) 88.89%);
    padding: toRem(34px) toRem(52px) toRem(30px) toRem(72px);
    position: relative;

    &:nth-child(1) {
      width: 94%;
    }

    &:nth-child(2) {
      width: 84%;

      .num-boxes__title {
        font-size: toRem(36.8px);
      }
    }

    &:nth-child(3) {
      width: 74%;
    }

    @media (max-width: 1024px) {
      width: calc(100% - 90px) !important;
    }  

    @media (max-width: 640px) {
      width: 100% !important;
      padding: 30px 40px;
    }  
  }

  &__title {
    color: #FFF;
    text-shadow: 0px 1.632938027381897px 1.632938027381897px rgba(0, 0, 0, 0.25);
    font-family: 'Benzin', sans-serif;
    font-size: toRem(44px);
    font-style: normal;
    font-weight: 900;
    line-height: 111.3%; /* 48.972px */
    text-transform: uppercase;

    @media (max-width: 1024px) {
      font-size: 28px !important;
    }

    @media (max-width: 900px) {
      font-size: 24px !important;
    }

    @media (max-width: 460px) {
      font-size: 18px !important;
    }
  }

  &__text {
    color: #FFF;
    font-size: toRem(30px);
    font-weight: 700;
    line-height: 90.3%;
    opacity: .7;
    margin-top: toRem(10px);
    padding-top: toRem(18px);
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: toRem(326px);
      height: toRem(1px);
      opacity: 0.3;
      background: #FFF;

      @media (max-width: 460px) {
        width: 100%;
      }
    }

    @media (max-width: 1024px) {
      font-size: 22px;
    }

    @media (max-width: 460px) {
      font-size: 16px !important;
      margin-top: 10px;
      padding-top: 12px;
    }
  }

  &__num {
    position: absolute;
    color: #FAAE54;
    text-shadow: 0px 2.3029637336730957px 2.3029637336730957px rgba(0, 0, 0, 0.25);
    font-family: 'Benzin', sans-serif;
    font-size: toRem(105.973px);
    font-weight: 400;
    line-height: toRem(35.986px);
    text-transform: uppercase;
    font-weight: 900;
    right: 97.3%;
    top: toRem(50px);

    @media (max-width: 640px) {
      font-size: 64px;
      right: auto;
      left: 2%;
      top: -10%;
    }
  }
}