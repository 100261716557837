.partner-program {
  background: #F7F1FF;
  position: relative;

  &__logos {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 0;

    @media (max-width: 1024px) {
      display: none;
    }

    & > svg {
      opacity: .5;
      &:first-child {
        position: absolute;
        bottom: 0%;
        right: 1%;
        width: toRem(260px);
        height: auto;
        translate: 0 -50%;
        rotate: -80deg;
      }
      &:last-child {
        position: absolute;
        bottom: 0%;
        left: 7%;
        width: toRem(200px);
        height: auto;
        translate: 0 -50%;
        rotate: -170deg;
      }
    }
  }

  &__holder {
    position: relative;
    margin: 0 auto toRem(-50px);
    z-index: 3;
    max-width: toRem(1030px);
    width: 100%;
    padding-top: toRem(224px);

    @media (max-width: 1024px) {
      position: relative;
      margin: 0px 0 -40px;
      padding-top: 100px;
    }

    @media (max-width: 960px) {
      translate: 0 -40px;
      margin: 0px 0 -80px;
    }

    @media (max-width: 960px) {
      padding-top: 0;
    }
  }
  
  &__box {
    background: #F7F1FF;
    border-radius: toRem(26px);
    box-shadow: toRem(0px) toRem(11.07472px) toRem(44.29887px) toRem(0px) rgba(42, 0, 95, 0.13);
    padding: toRem(60px) toRem(114px) toRem(55px);
    width: 100%;
    position: relative;
    z-index: 3;

    @media (max-width: 1024px) {
      padding: 30px 24px 74px;
    }
  }

  
  &__title {
    position: relative;
    width: max-content;
    z-index: 2;
    margin: auto;
    margin-bottom: toRem(60px);

    @media (max-width: 960px) {
      width: min-content;
    }

    span {
      font-family: 'Benzin';
      font-size: toRem(55px);
      font-weight: 900;
      text-transform: lowercase;
      font-variant: all-small-caps;

      background: linear-gradient(0deg, #77509D -13.33%, #B66DFD 88.89%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      line-height: 120%;

      @media (max-width: 960px) {
        display: block;
        text-align: center;
        margin: 0 auto 40px;
        line-height: 80%;
        font-size: toRem(92px);
      }

      @media (max-width: 768px) {
        font-size: 74px;
      }

      @media (max-width: 640px) {
        font-size: 60px;
      }

      @media (max-width: 520px) {
        font-size: 48px;
      }

      @media (max-width: 420px) {
        font-size: 38px;
      }

      @media (max-width: 360px) {
        font-size: 32px;
      }
    }

    svg {
      width: 90%;
      height: auto;
      position: absolute;
      bottom: toRem(-30px);
      left: 50%;
      z-index: -1;
      translate: -50% 0;
      opacity: .6;

      @media (max-width: 1024px) {
        bottom: 0px;
      }

      @media (max-width: 520px) {
        bottom: 0px;
      }
    }

    @media (max-width: 1024px) {
      margin: 35px auto 0;
    }
  }

  &__text {
    color: #4B3568;
    font-size: toRem(25.733px);
    font-weight: 300;
    line-height: 146.687%;
    opacity: 0.85;
    text-align: center;

    b {
      font-weight: 700;
    }

    @media (max-width: 1024px) {
      text-align: center;
      br {
        display: none;
      }
    }

    @media (max-width: 640px) {
      font-size: 20px;
      margin-top: 20px;
    }

    @media (max-width: 640px) {
      font-size: 18px;
      margin-top: 20px;
    }
  }
}