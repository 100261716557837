.top {
  background-image: url('../../assets/img/top-background.png');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 150%;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: toRem(125px);
    background: linear-gradient(0deg, #38006f, transparent);
  }
}

.header-block {
  width: 100%;
  z-index: 2;
  min-height: 100vh;

  padding: toRem(120px) 0 toRem(155px);

  align-items: center;
  display: grid;
  grid-template-columns: 1fr toRem(1060px) 1fr;
  overflow: hidden;
  max-width: 100vw;

  @media (max-width: 768px) {
    min-height: 90vh;
  }

  &:before {
    content: '';
    border-radius: 20px;
    background: linear-gradient(360deg, $gradientStart -13.33%, $gradientEnd 88.89%);
    width: 54px;
    height: 376px;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-50%, -50%);
  }

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0;
    opacity: .8;
    overflow: hidden;
    mix-blend-mode: lighten;

    &.this-3d {
      width: 100%;
    }

    iframe,
    video {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      left: 20%;
      top: 0;
      width: 100%;
      height: 100%;
      border: none !important;
      z-index: 2;

      @media (max-width: 1024px) {
        left: 0;
        opacity: .4;
      }

      @media (max-width: 640px) {
        object-position: center;
        object-fit: cover;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #38006f;
      opacity: .8;
    }
  }

  &__plus {
    position: absolute;
    width: toRem(80px);
    height: toRem(80px);

    @media (max-width: 640px) {
      display: none;
    }

    svg {
      position: absolute;
      width: toRem(80px);
      height: toRem(80px);

      &:nth-child(2) {
        translate: 0 toRem(-15px);
      }
    }

    &--first {
      top: 30%;
      right: 2%;
      rotate: 21deg;
    }

    &--second {
      top: 28%;
      right: 8%;
      scale: 0.4;
      rotate: 15deg;
    }

    &--thrid {
      top: 34%;
      right: 8%;
      scale: 0.2;
      rotate: 5deg;

      svg {
        animation: svgRotate 4s ease-in-out infinite;
      }

      @keyframes svgRotate {
        0% {rotate: 5;}
        50% {rotate: 15;}
        100% {rotate: 5;}
      }
    }
  }

  &__left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 1024px) {
      position: static;
    }
  }

  &__center {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1024px) {
      width: 85%;
      margin: auto;
    }

    @media (max-width: 640px) {
      width: 100%;
      padding: 0 50px;
    }
  }

  &__right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    @media (max-width: 1024px) {
      position: static;
    }
  }

  &__look-promo {
    writing-mode: vertical-lr;
    white-space: nowrap;
    rotate: 180deg;
    opacity: .5;
    color: #FFF;
    font-size: toRem(16.876px);
    font-weight: 200;
    line-height: 146.687%; /* 24.755px */
  }

  &__scroll-down {
    display: flex;
    align-items: flex-start;

    @media (max-width: 1024px) {
      position: absolute;
      left: 20px;
      bottom: 20px;
    }

    span {
      writing-mode: vertical-lr;
      white-space: nowrap;
      rotate: 180deg;
      opacity: .2;
      color: #FFF;
      font-size: toRem(16.876px);
      font-weight: 200;
      line-height: 146.687%; /* 24.755px */
    }

    svg, img {
      width: auto;
      height: toRem(108px);
    }
  }

  &__content {
    z-index: 2;
    position: relative;
  }

  &__line {
    opacity: 0.6;
    background: linear-gradient(360deg, $gradientStart -13.33%, $gradientEnd 88.89%);
    width: toRem(65px);
    position: absolute;
    left: toRem(15px);
    top: toRem(-180px);
    bottom: toRem(-55px);
    border-radius: 0 0 toRem(20px) toRem(20px);
    z-index: 0;

    @media (max-width: 640px) {
      width: 35px;
      left: 60px;
    }
  }

  &__video {
    display: flex;
    align-items: center;
    gap: toRem(18px);
    z-index: 4;
    cursor: pointer;

    &--button {
      width: toRem(96px);
      height: toRem(96px);
      background: linear-gradient(360deg, $gradientStart -13.33%, $gradientEnd 88.89%);
      border-radius: toRem(100px);
      position: relative;
      transition: .15s ease-in-out;

      @media (max-width: 640px) {
        z-index: 3;
        width: 54px;
        height: 54px;
      }

      svg {
        width: toRem(76px);
        height: toRem(76px);
        position: absolute;
        top: toRem(10px);
        left: toRem(10px);
        border-radius: toRem(100px);

        @media (max-width: 640px) {
          z-index: 3;
          width: 44px;
          height: 44px;
          top: toRem(5px);
          left: toRem(5px);
        }
      }
    }

    &--title {
      position: relative;
      z-index: 2;
      font-family: 'Dao Trinity';
      color: #fff;
      font-size: toRem(16px);
      line-height: 96.3%;
      letter-spacing: toRem(1.5px);
      text-transform: lowercase;
      translate: 0 toRem(-4px);
      opacity: 0.6;

      span {
        color: #AB64F2;
      }
    }
  }

  &__title-top {
    font-family: 'Benzin';
    font-size: toRem(53px);
    font-weight: 700;
    line-height: 100%; /* 46.501px */
    letter-spacing: toRem(3.09px);
    text-transform: uppercase;
    margin: toRem(20px) 0 toRem(16px);
    filter: url(#stroke-text-svg-filter);
    color: #fff;

    @media (max-width: 1024px) {
      font-size: 38px;
    }

    @media (max-width: 640px) {
      font-size: 24px;
      margin-bottom: 6px;
    }

    @media (max-width: 440px) {
      font-size: 20px;
    }
  }

  &__title-main {
    font-family: 'Benzin';
    font-size: toRem(75px);
    font-weight: 700;
    line-height: 100%; /* 104.201px */
    text-transform: uppercase;
    color: #F4E9FF;
    position: relative;
    z-index: 2;
    text-shadow: toRem(0px) toRem(2.7474751472473145px) toRem(2.7474751472473145px) rgba(0, 0, 0, 0.25);

    &.korean_title,
    &.chinese_title {
      font-size: toRem(90px);
      line-height: 110%;
      margin: toRem(-10px) 0;

      @media (max-width: 1024px) {
        font-size: 50px;
      }

      @media (max-width: 640px) {
        font-size: 34px;
      }
    }

    span {
      position: relative;
      z-index: 2;
      &:before {
        content: '';
        display: block;
        position: absolute;
        height: toRem(30px);
        width: 95%;
        background: url('../../assets/img/line.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        z-index: -1;
        left: 5%;
        bottom: toRem(10px);

        @media (max-width: 640px) {
          height: 16px;
          bottom: 0;
        }
      }
    }

    @media (max-width: 1024px) {
      font-size: 50px;
    }

    @media (max-width: 640px) {
      font-size: 34px;
    }

    @media (max-width: 440px) {
      font-size: 22px;
    }
  }

  &__bottom {
    position: relative;
    z-index: 2;
    padding-left: toRem(130px);

    @media (max-width: 1024px) {
      padding-left: 40px;
    }

    @media (max-width: 640px) {
      padding-left: 0;
    }

    &--title {
      margin: toRem(33px) 0 toRem(27px);
      opacity: 0.9;
      color: #FFF;
      font-size: toRem(19px);
      font-style: normal;
      font-weight: 400;
      line-height: 120%;

      @media (max-width: 1024px) {
        font-size: 16px;
        margin-bottom: 15px;
      }

      @media (max-width: 640px) {
        font-size: 15.5px;
      }

      @media (max-width: 440px) {
        font-size: 10px;
      }
    }
  }

  &__button-holder {
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    gap: toRem(10px);
  }

  &__button {
    width: toRem(300px);
    height: toRem(58px);
    border-radius: toRem(5px);
    color: #FAAE54;
    font-family: 'Benzin';
    padding-right: toRem(20px);
    font-weight: 900;
    text-shadow: toRem(0px) toRem(4px) toRem(4px) rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    font-size: toRem(18px);

    i {
      &.icon {
        right: toRem(20px);

        @media (max-width: 640px) {
          right: 15px;
        }
        
        svg, img {
          height: auto;
          width: toRem(14px);

          @media (max-width: 640px) {
            width: 9px;
          }

          path {
            fill: #FAAE54;
          }
        }
      }
    }

    @media (max-width: 640px) {
      height: 42px;
      width: 200px;
      font-size: 12px !important;
    }
  }

  &__socials {
    display: flex;
    align-items: flex-end;
    gap: toRem(9px);
    position: relative;
    z-index: 15;
    margin-bottom: toRem(100px);
    
    @media (max-width: 1024px) {
      position: absolute;
      right: 20px;
      bottom: auto;
      margin-bottom: 0;
    }

    @media (max-width: 640px) {
      display: none;
    }

    &--title {
      writing-mode: vertical-lr;
      white-space: nowrap;
      rotate: 180deg;
      opacity: .5;
      color: #FFF;
      font-size: toRem(16.876px);
      font-weight: 200;
      line-height: 146.687%; /* 24.755px */
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      margin-bottom: toRem(-15px);

      li {
        a {
          svg, img {
            width: toRem(37px);
            height: toRem(37px);
          }
        }
      }
    }
  }

  &__our-programs {
    position: absolute;
    bottom: toRem(10px);
    right: toRem(-150px);
    background: red;
    height: toRem(44px);
    width: toRem(500px);
    border-radius: 20px;
    background: linear-gradient(-90deg, #77509D 0%, #B66DFD 100%);

    span {
      color: #f8f0ff;
      text-shadow: toRem(0px) toRem(4.42378044128418px) toRem(4.42378044128418px) rgba(0, 0, 0, 0.25);
      font-size: toRem(27px);
      font-weight: 900;
      line-height: 96.3%;
      text-transform: uppercase;
      position: absolute;
      bottom: toRem(-10px);
      left: toRem(-10px);
    }
  }
}