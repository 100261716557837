.--mac {
  .menu__sign--up {
    font-variant: all-small-caps;
    font-size: toRem(16px);
  }
  .header-block__button {
    font-variant: all-small-caps;
    font-size: toRem(26px);

    @media (max-width: 460px) {
      font-size: 16px !important;
    }
  }
  .programs__block--button,
  .menu-block__button {
    font-variant: all-small-caps;
    font-size: toRem(26px);

    @media (max-width: 460px) {
      font-size: 20px !important;
    }
  }
  .ideology__button {
    font-variant: all-small-caps;
    font-size: toRem(22px);

    @media (max-width: 460px) {
      font-size: 18px !important;
    }
  }
  .rat-race-holder__new-economy {
    font-variant: small-caps;
    text-transform: lowercase;
    font-size: toRem(36px);
  }
  .rat-race__roberto--name {
    font-variant: all-small-caps;
    font-size: toRem(28px);
    padding: toRem(4px) toRem(25px) toRem(4px);
  }
  .rat-race__solution--name {
    font-size: toRem(36px);
    padding: toRem(3px) toRem(40px);
    font-variant: all-small-caps;

    @media (max-width: 460px) {
      font-size: 24px !important;
      padding: toRem(3px) toRem(15px) !important;
    }
  }
  .steps__list--number {
    font-variant: all-small-caps;
    font-size: toRem(66px);
  }
  .step-box__title {
    font-variant: all-small-caps;
    font-size: toRem(20px);
  }
  .consumer-fund__top-title {
    font-variant: all-small-caps;
    font-size: toRem(36px);
  }
  .programs__top-title {
    font-variant: all-small-caps;
    font-size: toRem(36px);

    @media (max-width: 1024px) {
      font-size: 28px;
    }

    @media (max-width: 960px) {
      font-size: 22px;
    }

    @media (max-width: 640px) {
      font-size: 18px;
    }

    @media (max-width: 360px) {
      font-size: 16px;
    }
  }
  .programs__block--header {
    font-variant: all-small-caps;
    font-size: toRem(30px);
  }
  .programs__block--levels li {
    font-variant: all-small-caps;
    font-size: toRem(11px);
    padding: toRem(0px) toRem(5px);
  }
}