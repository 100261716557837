.steps {
  background: url('../../assets/img/steps.jpg') center top no-repeat;
  background-size: 100%;
  position: relative;
  padding: toRem(170px) 0 0;
  // overflow: hidden;

  @media (max-width: 1024px) {
    padding: 0;
    background-size: 300%;
    overflow: visible;
  }

  &__top-title {
    position: absolute;
    top: toRem(40px);
    right: toRem(100px);
    color: #F0DFFF;
    text-shadow: toRem(0px) toRem(4.42378044128418px) toRem(4.42378044128418px) rgba(0, 0, 0, 0.25);
    font-size: toRem(30px);
    font-weight: 900;
    line-height: 100%;
    text-transform: uppercase;
    z-index: 2;

    @media (max-width: 1024px) and (min-width: 768px) {
      top: -140px;
    }

    @media (max-width: 768px) {
      font-size: 38px;
      line-height: 120%;
      font-family: 'Benzin', sans-serif;
      text-align: center;
      display: block;
      position: relative;
      margin-bottom: 90px;
      padding: 0 15px;
      top: 0;
      left: 0;
    }

    @media (max-width: 360px) {
      font-size: 32px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0px;
      right: toRem(-100px);
      height: toRem(47px);
      width: calc(100% + toRem(100px));
      translate: 0% -60%;
      z-index: -1;
      border-radius: toRem(20px) 0 0 toRem(20px);
      opacity: 0.5;
      background: linear-gradient(-90deg, $gradientStart -13.33%, $gradientEnd 88.89%);

      @media (max-width: 768px) {
        z-index: -1;
        bottom: 0px;
        top: auto;
        width: 220px;
        left: 50%;
        translate: -50% 0;
        height: 34px;
      }
    }
  }

  &__list {
    display: flex;
    gap: toRem(60px);
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
    list-style: none;
    position: relative;
    z-index: 2;

    @media (max-width: 460px) {
      gap: 40px;
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: toRem(1px);
      position: absolute;
      left: 0;
      top: 50%;
      translate: 0 -50%;
      background: #fffdef;
      box-shadow:
        0px 0px 2px rgb(255, 224, 166),
        0px 0px 5px rgb(255, 166, 0),
        0px 0px 15px 2px rgb(255, 51, 0);
      z-index: -1;
    }

    li {
      width: toRem(83px);
      height: toRem(83px);
      position: relative;
      cursor: pointer;

      @media (max-width: 460px) {
        width: 50px;
        height: 50px;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        width: 430%;
        height: 430%;
        border-radius: 50%;
        box-shadow: 
          inset 0px 0px 2px rgb(255, 224, 166),
          inset 0px 0px 5px rgb(255, 166, 0),
          inset 0px 0px 10px rgb(255, 51, 0),
          inset 0px 0px 12px #000,
          0px 0px 2px rgb(255, 224, 166),
          0px 0px 5px rgb(255, 166, 0),
          0px 0px 25px rgb(255, 51, 0);
        border: toRem(1px) solid #fffdef;
        opacity: 0;
        z-index: -1;
        transition: .2s;
        scale: .5;
      }

      &.--active {
        &:after {
          opacity: .2;
          scale: 1;
        }
        .steps__list--title {
          opacity: 0.5;
          visibility: visible;
          translate: -50% -180%;
        }
        .steps__list--number {
          background: #FAAE54;
          
          span {
            background: transparent;
            background-clip: none;
            -webkit-background-clip: none;
            -webkit-text-fill-color: initial;
            color: #45108A;
          }

          &:before {
            opacity: 1;
          }

          &:after {
            scale: 1;
            opacity: 1;
          }
        }
      }
    }

    &--title {
      position: absolute;
      top: 0;
      left: 50%;
      text-align: center;
      translate: -50% -120%;

      font-family: 'Benzin';
      font-size: toRem(32.557px);
      font-weight: 900;
      line-height: 113.3%;
      text-transform: uppercase;

      background: linear-gradient(88deg, #FFF 38.57%, rgba(255, 255, 255, 0.50) 98.14%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      transition: .2s;

      opacity: 0;
      visibility: hidden;

      @media (max-width: 460px) {
        font-size: 20px !important;
      }
    }

    &--number {
      width: toRem(83px);
      height: toRem(83px);
      position: relative;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;

      font-family: 'Benzin';
      font-size: toRem(46.136px);
      font-weight: 900;
      line-height: 100%;

      background: #49316F;
      border-radius: 50%;

      @media (max-width: 460px) {
        width: 50px;
        height: 50px;
        font-size: 27px !important;
      }

      span {
        background: linear-gradient(88deg, #FFF 38.57%, rgba(255, 255, 255, 0.50) 98.14%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        width: 130%;
        height: 130%;
        border-radius: 50%;
        opacity: .1;
        z-index: -1;
        box-shadow: 
          inset 0px 0px 0px 1px rgb(255, 166, 0),
          inset 0px 0px 2px rgb(255, 224, 166),
          inset 0px 0px 5px rgb(255, 166, 0),
          inset 0px 0px 10px rgb(255, 51, 0),
          inset 0px 0px 12px #000,
          0px 0px 1px 1px rgb(255, 252, 246),
          0px 0px 2px rgb(255, 224, 166),
          0px 0px 5px rgb(255, 166, 0),
          0px 0px 15px rgb(255, 51, 0);
        border: toRem(1px) solid #fffdef;
        transition: .2s;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        width: 110%;
        height: 110%;
        border-radius: 50%;
        opacity: 0;
        z-index: -1;
        box-shadow: 
          inset 0px 0px 0px 1px rgb(255, 166, 0),
          inset 0px 0px 2px rgb(255, 224, 166),
          inset 0px 0px 5px rgb(255, 166, 0),
          inset 0px 0px 10px rgb(255, 51, 0),
          inset 0px 0px 12px #000,
          0px 0px 1px 1px rgb(255, 252, 246),
          0px 0px 2px rgb(255, 224, 166),
          0px 0px 5px rgb(255, 166, 0),
          0px 0px 15px rgb(255, 51, 0);
        border: toRem(1px) solid #fffdef;
        transition: .2s;
      }
    }
  }

  &__block {
    margin-top: toRem(35px);
    overflow: hidden;

    &--title {
      color: #EEE;
      text-align: center;
      text-shadow: toRem(0px) toRem(4px) toRem(4px) rgba(0, 0, 0, 0.25);
      font-family: 'Benzin';
      font-size: toRem(47.063px);
      font-weight: 900;
      line-height: 120%; /* 53.322px */
      text-transform: uppercase;
      position: relative;
      z-index: 2;
      width: max-content;
      margin: toRem(0px) auto toRem(24px);
      padding-top: toRem(30px);

      @media (max-width: 768px) {
        font-size: 42px;
        width: 100%;
      }

      @media (max-width: 600px) {
        font-size: 28px;
      }

      @media (max-width: 360px) {
        font-size: 18px;
      }

      span {
        position: relative;
        z-index: 2;

        @media (max-width: 768px) {
          position: static;
        }

        svg {
          position: absolute;
          left: 0;
          bottom: toRem(-1px);
          width: 100%;
          height: auto !important;
          z-index: -1;

          @media (max-width: 768px) {
            width: 120vw;
            left: -10vw;
            bottom: -5px; 
          }
        }
      }
    }

    &--planet {
      position: relative;
      border-radius: 50%;
      height: toRem(260px);
      mix-blend-mode: color-dodge;

      &:before {
        content: '';
        position: absolute;
        top: toRem(-40px);
        left: 0;
        bottom: toRem(-40px);
        width: 100%;
        background: url('../../assets/img/planet_bg.svg') center no-repeat;
        background-size: 100%;
        opacity: .2;
      }

      &__holder {
        position: absolute;
        width: toRem(261px);
        height: toRem(261px);
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        z-index: 2;

        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          width: 100vw;
          background: #fff;
          height: 1px;
          box-shadow: 
            0px 0px 4px 3px #FFFFFF,
            0px 0px 10px 2px #FFFFFF,
            0px 0px 15px 5px #D4325A;
          opacity: .9;
          animation: light linear infinite .4s;
        }

        &:before {
          left: 0;
        }

        &:after {
          right: 100%;
        }
      }

      img {
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &--number {
      position: absolute;
      right: 0;
      top: 50%;
      font-family: 'Benzin';
      font-size: toRem(128.467px);
      font-weight: 900;
      line-height: 100%; /* 116.006px */
      text-transform: uppercase;
      translate: 180% -35%;
      text-shadow: none;
      
      background: linear-gradient(0deg, $gradientStart -13.33%, $gradientEnd 88.89%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (max-width: 1024px) {
        display: none;
      }
    }

    &--desc {
      color: #FFF;
      text-align: center;
      font-size: toRem(19.825px);
      font-weight: 300;
      line-height: 123.187%;
      opacity: .8;
  
      b {
        font-weight: 700;
      }

      span {
        color: #FAAE54;
      }

      margin-bottom: toRem(40px);

      @media (max-width: 768px) {
        padding: 0 15px;
        font-size: 16px;
        
        br {
          display: none;
        }
      }

      @media (max-width: 320px) {
        font-size: 13px;
        padding: 0 5px;
      }
    }

    &--content {
      display: flex;
      gap: toRem(18px);
      justify-content: center;
      height: toRem(260px);
      align-items: center;

      @media (max-width: 900px) {
        height: auto;
      }

      .slick {
        &-slider {
          overscroll-behavior-x: contain;
          overflow-x: scroll;
        }
        
        &-track {
          display: flex;
        }
      }

      .default-slick-arrow {
        position: absolute;
        top: calc(50% - 10px);
        width: 35px;
        height: 35px;
        z-index: 3;
        translate: 0 -50%;
        border: none;
        border-radius: 50%;
        overflow: hidden;

        font-size: 0;
        text-indent: 99999px;
        background: #FAAE54;

        &--prev {
          left: auto;
          right: calc(50% + ((toRem(260px) / 2) - (35px / 2)));
          @media (max-width: 460px) {
            right: calc(50% + ((toRem(280px) / 2) - (35px / 2)));
          }
        }

        &--next {
          right: auto;
          left: calc(50% + ((toRem(260px) / 2) - (35px / 2)));
          @media (max-width: 460px) {
            left: calc(50% + ((toRem(280px) / 2) - (35px / 2)));
          }
        }
      }
    }

    &--footer {
      color: #FFF;
      text-align: center;
      font-size: toRem(21.087px);
      font-weight: 300;
      line-height: 124.687%;
      margin-top: toRem(44px);

      @media (max-width: 900px) {
        margin-top: 20px;
      }

      @media (max-width: 768px) {
        font-size: 16px;
        padding: 0 20px;

        br {
          display: none;
        }
      }

      b {
        font-weight: 700;
      }

      span {
        color: #FAAE54;
        b {
          font-weight: 900;
        }
      }
    }

    &--hands-wrapper {
      display: flex;
      width: 100%;
      height: toRem(260px);
      align-items: center;
      justify-content: space-between;

      @media (max-width: 768px) {
        width: 140%;
        translate: -15% 0;
      }

      & > div {
        width: 33.333333333%;

        img {
          width: 100%;
        }
      }
    }

    &--hand {
      &.--first {

      }
      &.--second {
        rotate: 180deg;
      }
    }

    &--coin {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 80% !important;
        translate: 0 10%;
      }
    }
  }

  &__scroll-down {
    height: toRem(70px);
    background: rgba(250, 174, 84, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: toRem(2px) solid rgba(250, 174, 84, 0.2);
    border-bottom: toRem(2px) solid rgba(250, 174, 84, 0.2);
    box-shadow: toRem(0px) toRem(0px) toRem(47.12362289428711px) rgba(250, 174, 84, 0.19);
    margin: toRem(70px) 0 toRem(180px);

    svg {
      height: toRem(16px);
      width: auto;
    }

    @media (max-width: 1024px) {
      margin-bottom: 50px;
    }
  }

  &__holder {
    position: relative;
    // overflow: hidden;
  }

  &__arrow {
    position: absolute;
    width: calc(toRem(97px) / 2);
    height: toRem(97px);
    background: #FAAE54;
    border-radius: calc(toRem(97px) / 2) 0 0 calc(toRem(97px) / 2);
    top: toRem(300px);
    z-index: 3;
    color: #fff;
    display: flex;
    align-items: center;

    @media (max-width: 900px) {
      display: none;
    }

    svg {
      height: toRem(38px);
      width: auto;

      path {
        &:last-child {
          opacity: .2 !important;
        }
        &:first-child {
          opacity: 1 !important;
        }
      }
    }

    &--back {
      left: 0;
      padding-right: toRem(20px);
      justify-content: flex-end;
      border-radius: 0 calc(toRem(97px) / 2) calc(toRem(97px) / 2) 0;

      svg {
        rotate: 180deg;
      }
    }

    &--next {
      right: 0;
      padding-left: toRem(20px);
      justify-content: flex-start;
    }
  }
}

.light {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 50%;
  z-index: 2;
  translate: 0 -50%;
  opacity: .9;
  animation: light linear infinite .4s;
  box-shadow: 
    0px 0px 10px 15px #FFFFFF,
    0px 0px 30px 10px #FFFFFF,
    0px 0px 35px 10px #FFFFFF,
    0px 0px 45px 10px #D4325A;

  &.--first {
    left: 0;
  }

  &.--second {
    right: 0;
  }
}

@keyframes light {
  0%, 16% {
    opacity: .9;
  }
  20%, 33% {
    opacity: .7;
  }
  40%, 42% {
    opacity: .8;
  }
  44%, 50% {
    opacity: 1;
  }
  51%, 52% {
    opacity: .7;
  }
  53%, 58% {
    opacity: .8;
  }
  59%, 61% {
    opacity: 1;
  }
  67%, 70% {
    opacity: .8;
  }
  70%, 80% {
    opacity: .7;
  }
  80%, 82% {
    opacity: .9;
  }
  82%, 90% {
    opacity: 1;
  }
  91%, 96% {
    opacity: .7;
  }
  96%, 100% {
    opacity: 1;
  }
} 

.step-box {
  width: toRem(260px);
  background: linear-gradient(0deg, $gradientStart, $gradientEnd);
  border-radius: toRem(25px);
  padding: 0 toRem(20px) 0;
  display: flex !important;
  flex-direction: column;

  @media (max-width: 460px) {
    width: 280px;
  }

  &--holder {
    padding: 20px 20px 40px;
  }

  &__title {
    width: 100%;
    border-radius: toRem(25px);
    background: linear-gradient(102deg, #FAAE54 27.38%, #EFBA00 79.37%);
    box-shadow: 0px 2.38416px 2.38416px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: toRem(9px);
    height: toRem(40px);
    margin-top: toRem(-20px);

    color: #45108A;
    font-family: 'Benzin';
    font-size: toRem(14px);
    text-transform: uppercase;
    font-weight: 900;
    line-height: 100%;

    svg {
      height: toRem(20px);
      width: auto;
    }
  }

  &__picture {
    width: toRem(200px);
    position: relative;
    margin: toRem(20px) auto toRem(-35px);

    @media (max-width: 460px) {
      width: 260px;
    }

    &:after {
      content: '';
      display: block;
      padding-bottom: 85%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}