.promo-block {
  height: toRem(2000px);
  position: relative;
  margin-bottom: toRem(40px);

  @media (max-width: 1024px) {
    height: auto;
    margin: 0;
  }

  &__logo {
    position: absolute;
    height: toRem(66px);
    width: toRem(150px);
    top: 6%;
    left: 50%;
    translate: -50% 0;
    z-index: 4;

    @media (max-width: 1024px) {
      position: relative;
      margin: auto;
      translate: none;
      width: 100px;
      left: 0;
      top: 0;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      &:last-child {
        z-index: 0;
        scale: 1.6;
        opacity: .2;
      }
    }
  }

  &__title {
    font-family: 'Benzin', sans-serif;
    font-weight: 900;
    font-size: toRem(60px);
    line-height: 90%;
    writing-mode: vertical-rl;
    white-space: nowrap;
    rotate: 180deg;
    position: absolute;
    bottom: toRem(90px);
    left: calc((100vw - toRem(1300px)) / 2);
    color: #fff;
    text-transform: uppercase;

    @media (max-width: 1024px) {
      position: relative;
      left: 0;
      top: 0;
      bottom: 0;
      margin-bottom: 33px;
      writing-mode: initial;
      white-space: initial;
      rotate: 0deg;
      text-align: center;
      font-size: 48px;
    }

    @media (max-width: 640px) {
      font-size: 32px;
    }

    @media (max-width: 320px) {
      font-size: 24px;
    }

    span {
      color: #FAAE54;
    }
  }

  &__scene {
    min-height: toRem(600px);
    height: 100vh;
    width: 100%;
    opacity: 1;
    position: sticky;
    top: 0;
    z-index: 3;

    background-image: url('../../assets/img/promo-bg.jpg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 1024px) {
      min-height: auto;
      height: auto;
      position: static;
      background-size: cover;
    }


    &--canvas {
      @media (max-width: 1024px) {
        display: none;
      }
    }

    &:before {
      content: '';
      display: block;
      width: toRem(1300px);
      height: toRem(60px);
      border-radius: toRem(33px) toRem(33px) 0 0;
      background: #F9F4FF;
      bottom: toRem(-45px);
      left: 50%;
      translate: -50% 0;
      position: absolute;
      z-index: -1;

      @media (max-width: 1024px) {
        width: 90%;
        left: 5%;
        translate: 0;
        bottom: 0;
        z-index: 2;
        height: 45px;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: toRem(150px);
      background: linear-gradient(0deg, #38006f, transparent);
      z-index: -2;
    }
  }

  &__play-button {
    width: toRem(96px);
    height: toRem(96px);
    background: linear-gradient(360deg, #77509D 0%, #B66DFD 100%);
    border-radius: toRem(100px);
    position: relative;
    transition: .15s cubic-bezier(0.77, 0, 0.18, 1);
    position: absolute;
    top: 48%;
    left: 50%;
    translate: -50% -50%;
    
    @media (max-width: 1024px) {
      display: none;
    }

    &:hover {
      scale: 1.02;

      svg {
        scale: 0.93;
      }
    }

    svg {
      width: toRem(76px);
      height: toRem(76px);
      position: absolute;
      top: toRem(10px);
      left: toRem(10px);
      border-radius: toRem(100px);
      transition: .15s cubic-bezier(0.77, 0, 0.18, 1);
    }
  }

  &__mobile {
    display: none;
    width: 60%;
    margin: auto;
    padding: 70px 0 120px;

    &--holder {
      position: relative;
    }

    img {
      width: 100%;
    }

    @media (max-width: 1024px) {
      display: block;
      width: 70%;
    }

    @media (max-width: 640px) {
      width: 90%;
    }

    &--play {
      position: absolute;
      left: 50%;
      top: 50%;
      translate: -50% -55%;
    }
  }
}