.footer {
  background: url('../../assets/img/footer-2.png') top center no-repeat;
  background-size: cover;
  position: relative;
  z-index: 2;
  padding: toRem(115px) 0 toRem(40px);
  font-family: $font-barlow;
  margin-top: -150px;

  @media (max-width: 1024px) {
    margin-top: 0;
    background-color: #2B0962;
  }

  &__top {
    padding: toRem(22px) 0;
    display: flex;
    justify-content: center;
    margin-bottom: toRem(7px);

    svg {
      height: toRem(68px);
      width: auto;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 640px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 60px 0;
    }

    @media (max-width: 360px) {
      grid-template-columns: 1fr;
    }
  }

  &__column {
    &--title {
      color: #FFF;
      font-size: toRem(16.72px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
      opacity: .9;
      margin-bottom: toRem(28px);
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      gap: toRem(20px);
      flex-direction: column;

      a,
      li {
        color: #FFF;
        font-size: toRem(16.72px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        opacity: .9;
        display: flex;
        gap: toRem(7.32px);
        align-items: center;
        transition: .2s;

        &:hover {
          opacity: 1;
        }

        svg {
          width: toRem(20.9px);
        }
      }
    }

    &--contacts {
      gap: toRem(33px);
    }
  }

  &__socials {
    display: flex;
    justify-content: center;
    margin: 0 auto 0;
    padding-bottom: toRem(24px);
    position: relative;

    // &:after {
    //   content: '';
    //   display: block;
    //   width: 100%;
    //   height: toRem(1.5px);
    //   background: #fff;
    //   opacity: .1;
    //   position: absolute;
    //   left: 0;
    //   bottom: 0;
    // }

    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      gap: toRem(4px);

      li {
        svg {
          width: toRem(37px);
          height: toRem(37px);
        }
      }
    }
  }

  &__copyrights {
    color: #FFF;
    font-size: toRem(12.54px);
    font-style: normal;
    font-weight: 400;
    line-height: toRem(18.81px);

    &--main {
      display: flex;
      flex-direction: column;
      gap: toRem(9.21px);
      opacity: .7;

      p {
        margin: 0;
        text-align: center;
      }
    }

    &--columns {
      margin-top: toRem(33px);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0 toRem(62px);
      opacity: .7;

      @media (max-width: 640px) {
        grid-template-columns: 1fr;
      }
    }

    &--final {
      margin-top: toRem(60px);
      font-weight: 600;
      text-align: center;
      opacity: .7;
    }
  }
}